import BaseLayout from '../components/BaseLayout/BaseLayout'
import HomeLayout from '../components/Home/HomeLayout'
import { useDocumentTitle } from '../hooks/setDocumentTitle'
import { useEffect, useState } from 'react'
import CollectionManager from '../config/collection'
import { useDefaultChainId } from '../config/useDefaultChainId'
import { useModal } from 'react-simple-modal-provider'
export default function LandingPage() {
  useDocumentTitle('ArborSwap')
  const chain_id = useDefaultChainId()
  const { open: openLoadingModal, close: closeLoadingModal } = useModal('LoadingModal')

  const [cardCollections, setCardCollections] = useState([])
  const [searchQuery, setSearchQuery] = useState('') // Add search state

  useEffect(() => {
    async function getCollections() {
      openLoadingModal()
      const collectionManager = new CollectionManager(chain_id)
      const collectionsRes = await collectionManager.getCollections()
      // remove those which dont have any items, owner is unknown or image is empty
      const filteredCollections = collectionsRes.filter(
        (collection) =>
          collection.items > 0 &&
          collection.owner !== 'Unknown' &&
          collection.image !== '' &&
          collection.image !== 'Unknown',
      )
      setCardCollections(filteredCollections)
      closeLoadingModal()
    }
    getCollections()
  }, [])
  const filteredCollections = cardCollections
    // Filter out duplicates based on nft_collection_address
    .filter(
      (collection, index, self) =>
        index === self.findIndex((c) => c.nft_collection_address === collection.nft_collection_address),
    )
    // Apply search query filter if needed
    .filter((collection) => collection.name.toLowerCase().includes(searchQuery.toLowerCase()))

  return (
    <div className="w-full">
      <div className="base-layout pb-5 ">
        <BaseLayout onSearch={setSearchQuery}>
          <HomeLayout cardCollections={filteredCollections} />
        </BaseLayout>
      </div>
    </div>
  )
}
