import { useState, useEffect } from 'react'
import React from 'react'
import BidSVG from '../../../svgs/bid'
import Styles from '../NFT.module.css'
import { useEthers } from '@usedapp/core'
import MarketplaceManager from '../../../config/marketplace'
import { ethers } from 'ethers' // For ethers utilities like parseEther
import { useDefaultChainId } from '../../../config/useDefaultChainId'
import { BackendUrl } from '../../../config/constants'
import { toast } from 'react-toastify'
import { formatBigToNum } from '../../../utils/numberFormat'
import ERC20manager from '../../../config/erc20'
import { useModal } from 'react-simple-modal-provider'

export default function ListedPrice({
  image,
  listed_price,
  second_price,
  highest_bid,
  owner,
  tokenId,
  tokenAddress,
  marketItemId,
  biddingEnabled,
  listingStatus,
  tokenBearer,
}) {
  const [ownerView, setOwnerView] = useState(false)
  const { library, account } = useEthers() // Get library and account from useEthers hook
  const [showModal, setShowModal] = useState(false) // Modal state
  const [showBidModal, setShowBidModal] = useState(false)
  const [price, setPrice] = useState('') // Price input state
  const [token, setToken] = useState('0x4A2328F316f932d0528F746c48195c76073E9762') // Default token
  const [user, setUser] = useState(null)
  const [newBid, setNewBid] = useState(false)
  const [enableBidding, setEnableBidding] = useState(false)
  const chain_id = useDefaultChainId()
  const { open: openLoadingModal, close: closeLoadingModal } = useModal('LoadingModal')
  useEffect(() => {
    if (account) {
      if (owner === account) {
        setOwnerView(true)
      } else {
        setOwnerView(false)
      }
    }
  }, [account])

  useEffect(() => {
    async function getUser() {
      const response = await fetch(`${BackendUrl}/user/${account}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`,
        },
      })
      const data = await response.json()
      console.log(data)
      if (data.detail === 'User not found') {
        return
      }
      setUser(data)
      console.log(data)
    }
    if (account && token) {
      getUser()
    }
  }, [account, token])

  // Initialize MarketplaceManager with networkId 159 and the library
  const marketplaceManager = library && chain_id ? new MarketplaceManager(chain_id, library) : null

  // Add item to the marketplace
  const handleAddItemToMarket = async () => {
    if (!marketplaceManager || !account) return

    try {
      openLoadingModal()
      const priceInWei = ethers.utils.parseEther(price)
      const newItemId = await marketplaceManager.addItemToMarket(
        tokenId,
        tokenAddress,
        price,
        priceInWei,
        enableBidding,
        token,
      )
      console.log('Item added to the marketplace with ID:', newItemId)
      closeLoadingModal()
      toast.success('Item added to the marketplace')
      setShowModal(false) // Close modal after submission
      window.location.reload()
    } catch (error) {
      closeLoadingModal()
      toast.error('Error adding item to market')
      console.error('Error adding item to market:', error)
    }
  }

  // Buy an item from the marketplace
  const handleBuyItem = async () => {
    if (!marketplaceManager || !account || !marketItemId) return

    if (!user) {
      toast.error('Please complete your profile first.')
      return
    }
    if (!user.name || !user.profile_picture) {
      toast.error('Please complete your profile first.')
      return
    }

    try {
      // console.log(lis,"price")
      openLoadingModal()
      await marketplaceManager.buyItem(marketItemId, listed_price, tokenAddress, tokenId)
      console.log('Item purchased with ID:', marketItemId)
      closeLoadingModal()
      window.location.reload()
    } catch (error) {
      console.error('Error buying item:', error)
      closeLoadingModal()
      toast.error('Error buying item')
    }
  }

  // Place a bid on an auction item
  const handlePlaceBid = async () => {
    if (!user) {
      toast.error('Please complete your profile first.')
      return
    }
    if (!user.name || !user.profile_picture) {
      toast.error('Please complete your profile first.')
      return
    }
    if (!newBid) {
      toast.error('Please enter a bid amount.')
      return
    }
    if (newBid <= 0) {
      toast.error('Please enter a valid bid amount.')
      return
    }
    if (newBid <= highest_bid) {
      toast.error('Your bid amount must be greater than the current highest bid.')
      return
    }

    console.log(marketItemId, marketplaceManager, account, highest_bid)
    if (!marketplaceManager || !account || !marketItemId) return
    const bidAmountInWei = ethers.utils.parseEther(newBid.toString())

    try {
      // approve
      openLoadingModal()
      const erc20Manager = new ERC20manager(chain_id)
      await erc20Manager.approve(library, account, bidAmountInWei)
      // checking allowance
    } catch (error) {
      console.error('Error approving:', error)
      toast.error('Error approving')
      return
    }
    try {
      console.log(bidAmountInWei, 'bidAmountInWei')
      await marketplaceManager.placeABid(tokenId, bidAmountInWei, tokenAddress)
      closeLoadingModal()
      toast.success('Bid placed')
      window.location.reload()
    } catch (error) {
      console.error('Error placing a bid:', error)
      closeLoadingModal()
      toast.error('Error placing a bid')
    }
  }
  const endAuction = async () => {
    if (!marketplaceManager || !account || !marketItemId) return

    try {
      openLoadingModal()
      await marketplaceManager.endAuction(marketItemId, tokenAddress, tokenId, listed_price)
      console.log('Auction ended with ID:', marketItemId)
      closeLoadingModal()
      toast.success('Auction ended')
      window.location.reload()
    } catch (error) {
      console.error('Error ending auction:', error)
      closeLoadingModal()
      toast.error('Error ending auction')
    }
  }
  const displayPrice = () => {
    try {
      // Attempt to format the listed price using ethers.utils
      return listed_price ? ethers.utils.formatEther(listed_price.toString()) : '0'
    } catch (error) {
      console.log('Error formatting listed price:', error)
      // Display the raw listed price if formatting fails
      return listed_price ? listed_price.toString() : '0'
    }
  }
  return (
    <div className="bg-white dark:bg-black-shade2-background rounded-[20px] w-full px-9 py-9">
      {/* Modal Section */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-[#00000099] z-50 backdrop-blur-sm">
          <div className="bg-[#ffffff] dark:bg-[#1a1a1a] rounded-xl p-6 w-1/3 shadow-lg">
            <h2 className="text-xl font-semibold text-[#333333] dark:text-[#e5e5e5] mb-6">Add Item to Marketplace</h2>

            <label className="block text-sm font-medium text-[#666666] dark:text-[#cccccc] mb-2">
              Set Price (in ETH):
            </label>
            <input
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="block w-full p-3 border rounded-lg mb-5 border-[#cccccc] dark:border-[#444444] bg-[#f9f9f9] dark:bg-[#333333] text-[#333333] dark:text-[#f0f0f0]"
              placeholder="Enter price in ETH"
            />

            <label className="block text-sm font-medium text-[#666666] dark:text-[#cccccc] mb-2">Select Token:</label>
            <select
              value={token}
              onChange={(e) => setToken(e.target.value)}
              className="block w-full p-3 border rounded-lg mb-5 border-[#cccccc] dark:border-[#444444] bg-[#f9f9f9] dark:bg-[#333333] text-[#333333] dark:text-[#f0f0f0]"
            >
              <option value="0x4A2328F316f932d0528F746c48195c76073E9762">Default Token (ERC20)</option>
            </select>

            <div className="flex items-center mb-5">
              <label className="text-sm font-medium text-[#666666] dark:text-[#cccccc] mr-4">Enable Bidding:</label>
              <input
                type="checkbox"
                checked={enableBidding}
                onChange={(e) => setEnableBidding(e.target.checked)}
                className="form-checkbox h-5 w-5 text-[#70a143] border-[#cccccc] dark:border-[#444444] rounded focus:ring-[#70a143]"
              />
            </div>

            <div className="flex justify-end space-x-3">
              <button
                className="px-5 py-2 rounded-lg bg-[#f0f0f0] dark:bg-[#444444] text-[#666666] dark:text-[#cccccc] hover:bg-[#e0e0e0] dark:hover:bg-[#555555] transition-colors"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className="px-5 py-2 rounded-lg bg-green-700 text-[#ffffff] dark:bg-opacity-90 hover:bg-opacity-80 transition-colors"
                onClick={handleAddItemToMarket}
              >
                Add to Market
              </button>
            </div>
          </div>
        </div>
      )}
      {showBidModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50 backdrop-blur-md">
          <div className="bg-white dark:bg-black-shade2-background rounded-lg p-8 w-1/3 text-dark-text dark:text-dark-white-color">
            <h2 className="text-lg font-bold mb-4">Place a Bid</h2>
            <label className="block mb-2">Set Price (in ETH):</label>
            <input
              type="number"
              value={newBid}
              onChange={(e) => setNewBid(e.target.value)}
              className="block w-full p-2 border rounded mb-4"
              placeholder="Enter price in ETH"
            />
            <button className="bg-[#70a143] bg-opacity-30 text-white px-4 py-2 rounded" onClick={handlePlaceBid}>
              Place a Bid
            </button>
          </div>
        </div>
      )}

      {/* Regular UI */}
      <div className="flex justify-between">
        <span className="font-gilroy font-medium text-sm text-[#807373] dark:text-dark-gray">Listed Price</span>

        <div className={`flex items-center px-2 py-1 ${Styles.cardBox}`}>
          <img src="/images/nft/note.svg" alt="note" className="mr-2" />
          <span className="font-gilroy font-medium text-xs text-[#807373] dark:text-dark-gray">1 of 300</span>
        </div>
      </div>

      <div className="flex items-center mt-5">
        <div className="h-full mr-2">
          {/* <img src={image} alt="category" className="mr-2 object-cover w-full" /> */}
        </div>
        <span className="font-gilroy font-bold text-2xl text-dark-text dark:text-dark-white-color">
          {/* {ethers.utils.formatEther(listed_price.toString())} */}
          {displayPrice()}
          <span className="font-medium text-sm text-[#807373] dark:text-dark-gray">&nbsp;(${second_price})</span>
        </span>
      </div>

      <div className="flex items-center mt-7">
        <BidSVG className="mr-2 fill-dark-text dark:fill-dark-white-color" />
        <span className="font-gilroy font-medium text-sm text-[#807373] dark:text-dark-text-color">Highest Bid</span>

        <span className="font-gilroy font-semibold text-sm text-[#807373] dark:text-dark-gray ml-2">
          {highest_bid ? highest_bid : 0} BNB
        </span>
      </div>

      {ownerView && !listed_price && !biddingEnabled ? (
        <div className="flex w-full mt-10">
          <button
            onClick={() => setShowModal(true)} // Open modal on click
            className="disabled:bg-light-text bg-green-400 bg-opacity-30 py-4 w-full rounded-md font-gilroy font-bold flex items-center justify-center"
          >
            <BidSVG className="mr-2 fill-dark-text dark:fill-dark-white-color" />
            <span className="ml-2 text-dark-text dark:text-dark-white-color">Add Item to market</span>
          </button>
        </div>
      ) : ownerView && biddingEnabled && listingStatus === 'available' ? (
        <div className="flex w-full mt-10">
          <button
            onClick={endAuction}
            className="bg-light-text bg-opacity-30 py-4 w-full rounded-md font-gilroy font-bold flex items-center justify-center"
          >
            <BidSVG className="mr-2 fill-dark-text dark:fill-dark-white-color" />
            <span className="ml-2 text-dark-text dark:text-dark-white-color">End Auction</span>
          </button>
        </div>
      ) : null}
      {!ownerView && listed_price && listingStatus === 'available' ? (
        <>
          {!biddingEnabled ? (
            <div className="flex w-full mt-10">
              <button
                onClick={handleBuyItem}
                className="bg-primary-green py-4 w-full rounded-md font-gilroy font-bold text-[#F5F6F7] flex items-center justify-center"
              >
                <img src="/images/home/nfts/wallet-money.svg" alt="wallet" />
                <span className="ml-2">Buy Now</span>
              </button>
            </div>
          ) : (
            <div className="flex w-full mt-10">
              <button
                onClick={() => setShowBidModal(true)}
                className="bg-light-text bg-opacity-30 py-4 w-full rounded-md font-gilroy font-bold flex items-center justify-center"
              >
                <BidSVG className="mr-2 fill-dark-text dark:fill-dark-white-color" />
                <span className="ml-2 text-dark-text dark:text-dark-white-color">Place a Bid</span>
              </button>
            </div>
          )}
        </>
      ) : !ownerView && !listed_price && listingStatus === 'available' ? (
        <div className="flex w-full mt-10">
          <button className="bg-light-text bg-opacity-30 py-4 w-full rounded-md font-gilroy font-bold flex items-center justify-center">
            <BidSVG className="mr-2 fill-dark-text dark:fill-dark-white-color" />
            <span className="ml-2 text-dark-text dark:text-dark-white-color">Item Not Listed Yet!</span>
          </button>
        </div>
      ) : null}
      {listingStatus === 'sold' && (
        <div className="flex w-full mt-10">
          <button className="bg-light-text bg-opacity-30 py-4 w-full rounded-md font-gilroy font-bold flex items-center justify-center">
            <BidSVG className="mr-2 fill-dark-text dark:fill-dark-white-color" />
            <span className="ml-2 text-dark-text dark:text-dark-white-color">Item Sold</span>
          </button>
        </div>
      )}
    </div>
  )
}
