export const Networkswitch = [
    {
        iconimg: "/images/headericons/BinanceSmartChainBadge.svg",
        title: "Binance Smart Chain", 
        subtitle: "Connected", 
        status: true, 
        isActive: true
    },{
        iconimg: "/images/headericons/BinanceSmartChainBadge.svg",
        title: "BSC Testnet", 
        subtitle: "Not Connected", 
        status: false, 
        isActive: false
    },
    {
        iconimg: "/images/logo-small.svg",
        title: "Roburna",
        subtitle: "Not Connected",
        status: false,
        isActive: false
    },
        {
        iconimg: "/images/logo-small.svg",
        title: "Roburna Testnet",
        subtitle: "Not Connected",
        status: false,
        isActive: false
    }
/*         {
        iconimg: "/images/headericons/PolygonChain.svg",
        title: "Polygon Chain", 
        subtitle: "Not Connected", 
        status: false, 
        isActive: false
    } */
]