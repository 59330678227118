import * as React from 'react'

const DashboardSVG = (props) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.4}
      d="M18.333 7.1V3.315c0-1.175-.533-1.65-1.858-1.65h-3.367c-1.325 0-1.858.475-1.858 1.65v3.775c0 1.183.533 1.65 1.858 1.65h3.367c1.325.008 1.858-.467 1.858-1.642Z"
    />
    <path d="M18.333 16.475v-3.367c0-1.325-.533-1.858-1.858-1.858h-3.367c-1.325 0-1.858.533-1.858 1.858v3.367c0 1.325.533 1.858 1.858 1.858h3.367c1.325 0 1.858-.533 1.858-1.858ZM8.75 7.1V3.315c0-1.175-.534-1.65-1.859-1.65H3.525c-1.325 0-1.858.475-1.858 1.65v3.775c0 1.183.533 1.65 1.858 1.65H6.89c1.325.008 1.859-.467 1.859-1.642Z" />
    <path
      opacity={0.4}
      d="M8.75 16.475v-3.367c0-1.325-.534-1.858-1.859-1.858H3.525c-1.325 0-1.858.533-1.858 1.858v3.367c0 1.325.533 1.858 1.858 1.858H6.89c1.325 0 1.859-.533 1.859-1.858Z"
    />
  </svg>
)

export default DashboardSVG
