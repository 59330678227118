export const Collections = [
  {
    id: 1,
    name: 'DeGods Nfts',
    image: '/images/home/collections/cards/card_image.svg',
    verified: true,
    icon: '/images/home/collections/cards/creator_logo.svg',
    category: 'Ethereum',
    category_icon: '/images/home/collections/cards/ethereum.svg',
    floor_price: 44.014,
    item_quantity: '9,999 Items',
    volume: '250,000',
    change: '+100',
    listed_items: '5,478',
    cap: '$17,766,774',
    owners: '6,871',
    full_image: '/images/home/collections/cards/collection_full_image.svg',
    creator_logo: '/images/home/collections/cards/creator_logo.svg',
    creator_icon: '/images/home/collections/cards/binance.svg',
    creator_name: '0xc19....2dde',
    description:
      'A deflationary collection of degenerates, punks, and misfits. Gods of the metaverse & masters of our own universe. Powered by the Solana Blockchain. Your DeGod gives you the ability to mine DUST, access to our NFT tracking.',
    nfts: [1, 3, 5, 2, 4],
  },
  {
    id: 2,
    name: 'Frontiers Nfts',
    image: '/images/home/collections/cards/card_image.svg',
    verified: true,
    icon: '/images/home/collections/cards/card_icon.svg',
    category: 'Ethereum',
    category_icon: '/images/home/collections/cards/ethereum.svg',
    floor_price: 44.014,
    item_quantity: '9,999 Items',
    volume: '250,000',
    change: '+100',
    listed_items: '5,478',
    cap: '$17,766,774',
    owners: '6,871',
    full_image: '/images/home/collections/cards/collection_full_image.svg',
    creator_icon: '/images/home/collections/cards/binance.svg',
    creator_logo: '/images/home/collections/cards/creator_logo.svg',
    creator_name: '0xc19....2dde',
    description:
      'A deflationary collection of degenerates, punks, and misfits. Gods of the metaverse & masters of our own universe. Powered by the Solana Blockchain. Your DeGod gives you the ability to mine DUST, access to our NFT tracking.',
    nfts: [1, 2, 5, 6],
  },
  {
    id: 3,
    name: 'RTFKT - MNLTH',
    image: '/images/home/collections/cards/card_image.svg',
    verified: true,
    icon: '/images/home/collections/cards/card_icon.svg',
    category: 'Ethereum',
    category_icon: '/images/home/collections/cards/ethereum.svg',
    floor_price: 147.014,
    item_quantity: '31,999 Items',
    volume: '250,000',
    change: '+100',
    listed_items: '5,478',
    cap: '$17,766,774',
    owners: '6,871',
    full_image: '/images/home/collections/cards/collection_full_image.svg',
    creator_icon: '/images/home/collections/cards/binance.svg',
    creator_logo: '/images/home/collections/cards/creator_logo.svg',
    creator_name: '0xc19....2dde',
    description:
      'A deflationary collection of degenerates, punks, and misfits. Gods of the metaverse & masters of our own universe. Powered by the Solana Blockchain. Your DeGod gives you the ability to mine DUST, access to our NFT tracking.',
    nfts: [2, 4, 5, 3],
  },
  {
    id: 4,
    name: 'DeGods Nfts',
    image: '/images/home/collections/cards/card_image.svg',
    verified: true,
    icon: '/images/home/collections/cards/card_icon.svg',
    category: 'Ethereum',
    category_icon: '/images/home/collections/cards/ethereum.svg',
    floor_price: 44.014,
    item_quantity: '9,999 Items',
    volume: '250,000',
    change: '+100',
    listed_items: '5,478',
    cap: '$17,766,774',
    owners: '6,871',
    full_image: '/images/home/collections/cards/collection_full_image.svg',
    creator_icon: '/images/home/collections/cards/binance.svg',
    creator_logo: '/images/home/collections/cards/creator_logo.svg',
    creator_name: '0xc19....2dde',
    description:
      'A deflationary collection of degenerates, punks, and misfits. Gods of the metaverse & masters of our own universe. Powered by the Solana Blockchain. Your DeGod gives you the ability to mine DUST, access to our NFT tracking.',
    nfts: [1, 4, 6, 2],
  },
  {
    id: 6,
    name: 'Frontiers Nfts',
    image: '/images/home/collections/cards/card_image.svg',
    verified: true,
    icon: '/images/home/collections/cards/card_icon.svg',
    category: 'Ethereum',
    category_icon: '/images/home/collections/cards/ethereum.svg',
    floor_price: 44.014,
    item_quantity: '9,999 Items',
    volume: '250,000',
    change: '+100',
    listed_items: '5,478',
    cap: '$17,766,774',
    owners: '6,871',
    full_image: '/images/home/collections/cards/collection_full_image.svg',
    creator_icon: '/images/home/collections/cards/binance.svg',
    creator_logo: '/images/home/collections/cards/creator_logo.svg',
    creator_name: '0xc19....2dde',
    description:
      'A deflationary collection of degenerates, punks, and misfits. Gods of the metaverse & masters of our own universe. Powered by the Solana Blockchain. Your DeGod gives you the ability to mine DUST, access to our NFT tracking.',
    nfts: [4, 5, 6, 1],
  },
  {
    id: 5,
    name: 'RTFKT - MNLTH',
    image: '/images/home/collections/cards/card_image.svg',
    verified: true,
    icon: '/images/home/collections/cards/card_icon.svg',
    category: 'Ethereum',
    category_icon: '/images/home/collections/cards/ethereum.svg',
    floor_price: 147.014,
    item_quantity: '31,999 Items',
    volume: '250,000',
    change: '+100',
    listed_items: '5,478',
    cap: '$17,766,774',
    owners: '6,871',
    full_image: '/images/home/collections/cards/collection_full_image.svg',
    creator_icon: '/images/home/collections/cards/binance.svg',
    creator_logo: '/images/home/collections/cards/creator_logo.svg',
    creator_name: '0xc19....2dde',
    description:
      'A deflationary collection of degenerates, punks, and misfits. Gods of the metaverse & masters of our own universe. Powered by the Solana Blockchain. Your DeGod gives you the ability to mine DUST, access to our NFT tracking.',
    nfts: [2, 3, 5, 1, 4],
  },
]

export const NFTs = [
  {
    id: 1,
    name: 'ArbowMan #475',
    listed_price: '473,597',
    second_price: '137,588',
    owner: 'ArborSwap NFTs',
    highest_bid: '250,700',
    creator: 'ThreeDee',
    owner_image: '/images/nft/owner.svg',
    creator_image: '/images/nft/creator.svg',
    image: '/images/home/nfts/nft.svg',
    verified: true,
    likes: 147,
    contract_address: '0xc19....2dde',
    token_id: '5597',
    token_standard: 'ERC-20',
    chain_type: 'Ethereum',
    creator_royality: '10%',
    category_icon: '/images/home/nfts/category.svg',
    description: 'The Original Defense Robot Prototype developed by the International Space Security Administration.',
  },
  {
    id: 2,
    name: 'Backbone #475',
    listed_price: '473,597',
    second_price: '137,588',
    highest_bid: '250,700',
    creator: 'ThreeDee',
    owner: 'ArborSwap NFTs',
    owner_image: '/images/nft/owner.svg',
    creator_image: '/images/nft/creator.svg',
    image: '/images/home/nfts/nft.svg',
    verified: true,
    likes: 127,
    contract_address: '0xc19....2dde',
    token_id: '5597',
    token_standard: 'ERC-20',
    chain_type: 'Ethereum',
    creator_royality: '10%',
    category_icon: '/images/home/nfts/category.svg',
    description: 'The Original Defense Robot Prototype developed by the International Space Security Administration.',
  },
  {
    id: 3,
    name: 'Healing Cells #175',
    listed_price: '473,597',
    second_price: '137,588',
    owner: 'ArborSwap NFTs',
    owner_image: '/images/nft/owner.svg',
    creator_image: '/images/nft/creator.svg',
    highest_bid: '250,700',
    creator: 'ThreeDee',
    image: '/images/home/nfts/nft.svg',
    verified: true,
    likes: 122,
    contract_address: '0xc19....2dde',
    token_id: '5597',
    token_standard: 'ERC-20',
    chain_type: 'Ethereum',
    creator_royality: '10%',
    category_icon: '/images/home/nfts/category.svg',
    description: 'The Original Defense Robot Prototype developed by the International Space Security Administration.',
  },
  {
    id: 4,
    name: 'ArbowMan #475',
    listed_price: '473,597',
    second_price: '137,588',
    owner: 'ArborSwap NFTs',
    highest_bid: '250,700',
    creator: 'ThreeDee',
    image: '/images/home/nfts/nft.svg',
    verified: true,
    likes: 117,
    owner_image: '/images/nft/owner.svg',
    creator_image: '/images/nft/creator.svg',
    contract_address: '0xc19....2dde',
    token_id: '5597',
    token_standard: 'ERC-20',
    chain_type: 'Ethereum',
    creator_royality: '10%',
    category_icon: '/images/home/nfts/category.svg',
    description: 'The Original Defense Robot Prototype developed by the International Space Security Administration.',
  },
  {
    id: 5,
    name: 'Backbone #475',
    listed_price: '473,597',
    second_price: '137,588',
    highest_bid: '250,700',
    owner: 'ArborSwap NFTs',
    image: '/images/home/nfts/nft.svg',
    creator: 'ThreeDee',
    verified: true,
    likes: 110,
    contract_address: '0xc19....2dde',
    token_id: '5597',
    token_standard: 'ERC-20',
    chain_type: 'Ethereum',
    creator_royality: '10%',
    owner_image: '/images/nft/owner.svg',
    creator_image: '/images/nft/creator.svg',
    category_icon: '/images/home/nfts/category.svg',
    description: 'The Original Defense Robot Prototype developed by the International Space Security Administration.',
  },
  {
    id: 6,
    name: 'Healing Cells #175',
    listed_price: '473,597',
    second_price: '137,588',
    highest_bid: '250,700',
    owner: 'ArborSwap NFTs',
    image: '/images/home/nfts/nft.svg',
    creator: 'ThreeDee',
    verified: true,
    likes: 110,
    contract_address: '0xc19....2dde',
    token_id: '5597',
    token_standard: 'ERC-20',
    owner_image: '/images/nft/owner.svg',
    creator_image: '/images/nft/creator.svg',
    chain_type: 'Ethereum',
    creator_royalty: '10%',
    chain_image: '/images/nfts/ethereum_detail.svg',
    category_icon: '/images/home/nfts/category.svg',
    description: 'The Original Defense Robot Prototype developed by the International Space Security Administration.',
  },
]

export const Activities = [
  {
    id: 1,
    collection_id: 1,
    name: 'Electric Bullets [Stellar]',
    image: '/images/collection/activity/activity.svg',
    action: 'Sold',
    price: 15.4,
    time: '2 hours ago',
    by: 'The Other Guy',
    to: '0xdv1....44a46',
    by_icon: '/images/collection/activity/by_icon.svg',
    to_icon: '/images/collection/activity/by_icon.svg',
  },
  {
    id: 2,
    collection_id: 1,
    name: 'Data Center [Epic]',
    image: '/images/collection/activity/activity.svg',
    action: 'Bid Accepted',
    price: 11.4,
    time: '3 hours ago',
    by: 'ThreeDee',
    to: 'FourDee',
    by_icon: '/images/collection/activity/by_icon.svg',
    to_icon: '/images/collection/activity/by_icon.svg',
  },
  {
    id: 3,
    collection_id: 1,
    name: 'Electric Bullets [Stellar]',
    image: '/images/collection/activity/activity.svg',
    action: 'Sold',
    price: 15.4,
    time: '2 hours ago',
    by: 'The Other Guy',
    to: '0xdv1....44a46',
    by_icon: '/images/collection/activity/by_icon.svg',
    to_icon: '/images/collection/activity/by_icon.svg',
  },
  {
    id: 4,
    collection_id: 2,
    name: 'Data Center [Epic]',
    image: '/images/collection/activity/activity.svg',
    action: 'Bid Accepted',
    price: 11.4,
    time: '3 hours ago',
    by: 'ThreeDee',
    to: 'FourDee',
    by_icon: '/images/collection/activity/by_icon.svg',
    to_icon: '/images/collection/activity/by_icon.svg',
  },
  {
    id: 5,
    collection_id: 5,
    name: 'Electric Bullets [Stellar]',
    image: '/images/collection/activity/activity.svg',
    action: 'Sold',
    price: 15.4,
    time: '2 hours ago',
    by: 'The Other Guy',
    to: '0xdv1....44a46',
    by_icon: '/images/collection/activity/by_icon.svg',
    to_icon: '/images/collection/activity/by_icon.svg',
  },
  {
    id: 6,
    collection_id: 4,
    name: 'Data Center [Epic]',
    image: '/images/collection/activity/activity.svg',
    action: 'Bid Accepted',
    price: 11.4,
    time: '3 hours ago',
    by: 'ThreeDee',
    to: 'FourDee',
    by_icon: '/images/collection/activity/by_icon.svg',
    to_icon: '/images/collection/activity/by_icon.svg',
  },
  {
    id: 7,
    collection_id: 1,
    name: 'Electric Bullets [Stellar]',
    image: '/images/collection/activity/activity.svg',
    action: 'Sold',
    price: 15.4,
    time: '2 hours ago',
    by: 'The Other Guy',
    to: '0xdv1....44a46',
    by_icon: '/images/collection/activity/by_icon.svg',
    to_icon: '/images/collection/activity/by_icon.svg',
  },
  {
    id: 8,
    collection_id: 3,
    name: 'Data Center [Epic]',
    image: '/images/collection/activity/activity.svg',
    action: 'Bid Accepted',
    price: 11.4,
    time: '3 hours ago',
    by: 'ThreeDee',
    to: 'FourDee',
    by_icon: '/images/collection/activity/by_icon.svg',
    to_icon: '/images/collection/activity/by_icon.svg',
  },
]

export const ActivitiesNFT = [
  {
    id: 1,
    nft_id: 1,
    name: 'Electric Bullets [Stellar]',
    action: 'Sold',
    ethereum: 77,
    dollar: 120478,
    time: '2 hours ago',
    by: 'The Other Guy',
    by_icon: '/images/nft/creator.svg',
  },
  {
    id: 2,
    nft_id: 1,
    name: 'Data Center [Epic]',
    action: 'Bid Accepted',
    ethereum: 77,
    dollar: 120478,
    time: '3 hours ago',
    by: 'ThreeDee',
    by_icon: '/images/nft/creator.svg',
  },
  {
    id: 3,
    nft_id: 1,
    name: 'Electric Bullets [Stellar]',
    action: 'Bid Accepted',
    ethereum: 77,
    dollar: 120478,
    time: '3 hours ago',
    by: 'ThreeDee',
    by_icon: '/images/nft/creator.svg',
  },
  {
    id: 4,
    nft_id: 2,
    name: 'Data Center [Epic]',
    action: 'Bid Accepted',
    ethereum: 77,
    dollar: 120478,
    time: '3 hours ago',
    by: 'ThreeDee',
    by_icon: '/images/nft/creator.svg',
  },
  {
    id: 5,
    nft_id: 5,
    name: 'Data Center [Epic]',
    action: 'Bid Accepted',
    ethereum: 77,
    dollar: 120478,
    time: '3 hours ago',
    by: 'ThreeDee',
    by_icon: '/images/nft/creator.svg',
  },
  {
    id: 6,
    nft_id: 4,
    name: 'Data Center [Epic]',
    action: 'Bid Accepted',
    ethereum: 77,
    dollar: 120478,
    time: '3 hours ago',
    by: 'ThreeDee',
    by_icon: '/images/nft/creator.svg',
  },
  {
    id: 7,
    nft_id: 1,
    name: 'Data Center [Epic]',
    action: 'Bid Accepted',
    ethereum: 77,
    dollar: 120478,
    time: '3 hours ago',
    by: 'ThreeDee',
    by_icon: '/images/nft/creator.svg',
  },
  {
    id: 8,
    nft_id: 3,
    name: 'Data Center [Epic]',
    action: 'Bid Accepted',
    ethereum: 77,
    dollar: 120478,
    time: '3 hours ago',
    by: 'ThreeDee',
    by_icon: '/images/nft/creator.svg',
  },
]

export const NFT_info = [
  {
    id: 1,
    nft_id: 1,
    info: [
      {
        name: 'Block',
        type: 'Flash',
        rarity: '48.1% Rarity',
      },
      {
        name: 'Generation',
        type: 'Genesis',
        rarity: '96.2% Rarity',
      },
      {
        name: 'Series',
        type: 'Series One',
        rarity: '90.4% Rarity',
      },
      {
        name: 'Tier',
        type: 'Stellar',
        rarity: '0.5% Rarity',
      },
      {
        name: 'Tier',
        type: 'Stellar',
        rarity: '0.5% Rarity',
      },
      {
        name: 'Tier',
        type: 'Stellar',
        rarity: '0.5% Rarity',
      },
    ],
  },
  {
    id: 2,
    nft_id: 2,
    info: [
      {
        name: 'Block',
        type: 'Flash',
        rarity: '48.1% Rarity',
      },
      {
        name: 'Generation',
        type: 'Genesis',
        rarity: '96.2% Rarity',
      },
      {
        name: 'Series',
        type: 'Series One',
        rarity: '90.4% Rarity',
      },
      {
        name: 'Tier',
        type: 'Stellar',
        rarity: '0.5% Rarity',
      },
      {
        name: 'Tier',
        type: 'Stellar',
        rarity: '0.5% Rarity',
      },
      {
        name: 'Tier',
        type: 'Stellar',
        rarity: '0.5% Rarity',
      },
    ],
  },
  {
    id: 3,
    nft_id: 3,
    info: [
      {
        name: 'Block',
        type: 'Flash',
        rarity: '48.1% Rarity',
      },
      {
        name: 'Generation',
        type: 'Genesis',
        rarity: '96.2% Rarity',
      },
      {
        name: 'Series',
        type: 'Series One',
        rarity: '90.4% Rarity',
      },
      {
        name: 'Tier',
        type: 'Stellar',
        rarity: '0.5% Rarity',
      },
      {
        name: 'Tier',
        type: 'Stellar',
        rarity: '0.5% Rarity',
      },
      {
        name: 'Tier',
        type: 'Stellar',
        rarity: '0.5% Rarity',
      },
    ],
  },
  {
    id: 4,
    nft_id: 4,
    info: [
      {
        name: 'Block',
        type: 'Flash',
        rarity: '48.1% Rarity',
      },
      {
        name: 'Generation',
        type: 'Genesis',
        rarity: '96.2% Rarity',
      },
      {
        name: 'Series',
        type: 'Series One',
        rarity: '90.4% Rarity',
      },
      {
        name: 'Tier',
        type: 'Stellar',
        rarity: '0.5% Rarity',
      },
      {
        name: 'Tier',
        type: 'Stellar',
        rarity: '0.5% Rarity',
      },
      {
        name: 'Tier',
        type: 'Stellar',
        rarity: '0.5% Rarity',
      },
    ],
  },
]

export const chain_types = [
  {
    id: 56,
    chainId: 56,
    name: 'Binance Chain',
    icon: '/images/create_nft/binance_coin.svg',
    est_gas_fee: '40',
  },
  {
    id: 97,
    chainId: 97,
    name: 'Binance Testnet',
    icon: '/images/create_nft/binance_coin.svg',
    est_gas_fee: '50',
  },
  {
    id: 158,
    chainId: 158,
    name: 'Roburna Chain',
    icon: '/images/logo-small.svg',
    est_gas_fee: '70',
  },
  {
    id: 159,
    chainId: 159,
    name: 'Roburna Testnet',
    icon: '/images/logo-small.svg',
    est_gas_fee: '70',
  },
]

export const profile_chain_types = [
  {
    id: 0,
    name: 'All Chain',
    icon: '/images/user_profile/all_chain.svg',
  },
  {
    id: 1,
    name: 'Binance Chain',
    icon: '/images/create_nft/binance_coin.svg',
    est_gas_fee: '40',
  },
  {
    id: 2,
    name: 'Ethereum Chain',
    icon: '/images/create_nft/ethereum.svg',
    est_gas_fee: '50',
  },
  {
    id: 3,
    name: 'Solana Chain',
    icon: '/images/create_nft/solana.svg',
    est_gas_fee: '70',
  },
  {
    id: 4,
    name: 'Polygon Chain',
    icon: '/images/create_nft/polygon.svg',
    est_gas_fee: '70',
  },
]
