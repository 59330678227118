// NftActivityTab.js
import React, { useEffect, useState } from 'react'
import LinkArrowSVG from '../../../svgs/link_arrow'
import { BackendUrl } from '../../../config/constants'

const NftActivityTab = ({ activity, index, action, sale, token }) => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    async function getUser() {
      const response = await fetch(`${BackendUrl}/user/${activity.bidder || activity.buyer}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const data = await response.json()
      console.log(data)
      if (data.detail === 'User not found') {
        return
      }
      setUser(data)
    }
    if ((activity.bidder || activity.buyer) && token) {
      getUser()
    }
  }, [activity.bidder, sale, token])
  console.log(sale)

  return (
    <div
      key={index}
      className="flex items-center border-b border-dashed border-opacity-30 border-[#767779] justify-between px-4 py-5"
    >
      <div className="flex items-center">
        <img
          src={BackendUrl + '/' + user?.profile_picture}
          alt={activity.by}
          className="w-5 h-5"
          onError={(e) => {
            e.target.onerror = null
            e.target.src = '/images/create_nft/upload_file.jpeg'
          }}
        />

        <div className="ml-4">
          <div className="flex items-center">
            <span className="font-gilroy text-sm font-bold  text-dark-text dark:text-dark-white-color">
              {user?.name}
            </span>
            <span className="font-gilroy font-medium text-sm text-light-text dark:text-dark-text-color ml-2">
              {action}
            </span>
          </div>

          <div className="flex items-center">
            <span className="font-gilroy font-medium text-xs text-light-text dark:text-dark-text-color">
              {!sale ? activity?.bid_time.toLocaleString() : activity?.sold_at}
            </span>
            <LinkArrowSVG className="ml-2 stroke-dark-text dark:stroke-dark-white-color fill-none" />
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <span className="font-gilroy font-bold text-sm text-dark-text dark:text-dark-white-color">
          {!sale ? activity?.bid_amount : activity?.price} ETH
        </span>

        <span className="font-gilroy font-medium text-xs text-light-text dark:text-dark-text-color mt-[7px]">
          ${!sale ? activity?.bid_amount : activity?.price}
        </span>
      </div>
    </div>
  )
}

export default NftActivityTab
