import React, { useEffect, useState } from 'react'
import PreviewDetails from './Subcomponents/PreviewDetails'
import PreviewHeader from './Subcomponents/PreviewHeader'
import { chain_types, Collections } from '../../data/cards'
import BackArrowSVG from '../../svgs/back_arrow'
import CollectionManager from '../../config/collection'
import { useDefaultChainId } from '../../config/useDefaultChainId'
import NFTManager from '../../config/nft'
import { useEthers } from '@usedapp/core'
import { useModal } from 'react-simple-modal-provider'

export default function Preview({ nft, nftType, nftChainType, setActive }) {
  const chain_id = useDefaultChainId()
  const [collections, setCollections] = React.useState([])
  const { open: openLoadingModal, close: closeLoadingModal } = useModal('LoadingModal')

  const { library, account } = useEthers()

  useEffect(() => {
    async function getCollections() {
      const collectionManager = new CollectionManager(chain_id)
      const collectionsRes = await collectionManager.getCollections()
      setCollections(collectionsRes)
    }
    getCollections()
  }, [])

  const getCollectionName = (address) => {
    const collection = collections.find((collection) => collection.nft_collection_address === address)
    return collection?.name
  }

  const handleSubmit = async () => {
    if (!account || !library) {
      alert('Please connect your wallet')
    }
    openLoadingModal()
    console.log(nft, 'nft')
    const nftManager = new NFTManager(chain_id)
    const formData = new FormData()
    formData.append('file', nft.file)
    formData.append('name', nft.name)
    formData.append('description', nft.description)
    formData.append('attributes', JSON.stringify(nft.properties))
    let copies
    if (nft.copies === '') {
      copies = 1
    } else {
      copies = nft.copies
    }

    const mintedTokenId = await nftManager.mintNFT(library, account, nft.collection, formData, nft, copies)
    // wait for 2 seconds
    setTimeout(() => {
      closeLoadingModal()
      window.location.href = '/profile'
    }, 2000)
  }

  return (
    <div className="preview">
      <div className="flex items-start w-full mt-9">
        <img className="w-32 h-32 mr-5" src={nft?.image} alt={nft?.name} />

        <div className="nft-info flex flex-col px-2">
          <span className="text-xl font-gilroy font-bold text-dark-text dark:text-dark-white-color">{nft?.name}</span>
          <span className="mt-2 md:mt-0 font-gilroy font-medium text-[#807373] dark:text-dark-gray">
            {nft?.description}
          </span>
        </div>
      </div>

      <PreviewHeader heading="NFT Properties" />

      {nft?.properties &&
        nft?.properties.map((property, index) => <PreviewDetails name={property.name} value={property.value} />)}

      <PreviewHeader heading="NFT Details" />

      {nftType === 1 && nft?.copies && <PreviewDetails name="Number of Copies" value={nft?.copies} />}

      <PreviewDetails name="Collection" value={getCollectionName(nft?.collection)} />

      <PreviewDetails name="Chain Type" icon={chain_types[nftChainType].icon} value={chain_types[nftChainType].name} />

      <PreviewDetails name="Contract Address" value={'0xc19....2dde'} />

      <PreviewDetails name="Royalties" value={nft?.royalties} />

      <div className="mt-10">
        <div className="flex justify-end items-center mb-10">
          <button
            className="bg-white dark:bg-transparent mr-5 flex items-center gap-2 py-[10px] px-5"
            onClick={() => setActive('Add Details')}
          >
            <BackArrowSVG className="fill-dark-text dark:fill-dark-white-color" />
            <span className="font-gilroy font-medium text-sm text-dark-text dark:text-dark-white-color">Go Back</span>
          </button>

          <button
            onClick={handleSubmit}
            className="bg-primary-green disabled:bg-light-text text-white font-gilroy font-bold px-8 py-3 rounded-md"
          >
            Create
          </button>
        </div>
      </div>
    </div>
  )
}
