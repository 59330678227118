import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { NFTs } from '../data/cards'
import BaseLayout from '../components/BaseLayout/BaseLayout'
import NFT from '../components/NFT/NFT'
import { BackendUrl } from '../config/constants'

export default function NftPage() {
  const { id, tokenId } = useParams()

  const [nft, setNft] = useState(null)
  const [token, setToken] = useState(null)

  useEffect(() => {
    const getTokenFromLocalStorage = async () => {
      const token = localStorage.getItem('token')
      setToken(token)
    }
    getTokenFromLocalStorage()
  }, [])

  useEffect(() => {
    // const res = fetch(`${BackendUrl}/nft/${id}`)
    // setNft(res)
    async function getNft() {
      const res = await fetch(`${BackendUrl}/nft/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      setNft(await res.json())
    }
    getNft()
  }, [id, token])

  useEffect(() => {
    if (nft) {
      document.title = nft.name
    }
  }, [nft])

  return <BaseLayout>{nft && <NFT nft={nft} tokenId={tokenId} />}</BaseLayout>
}
