import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Collections, NFTs, Activities } from '../data/cards'
import BaseLayout from '../components/BaseLayout/BaseLayout'
import Collection from '../components/Collection/Collection'
import ExtendedLayout from '../components/ExtendedLayout/ExtendedLayout'
import FilterBar from '../components/Collection/FilterBar'
import CollectionItems from '../components/Collection/CollectionItems'
import CollectionActivity from '../components/Collection/CollectionActivity'
import { SidebarContext } from '../context/SidebarContext/GlobalProvider'
import { BackendUrl } from '../config/constants'

export default function CollectionPage() {
  const { id } = useParams()
  const [collection, setCollection] = useState(null)
  const [item, setItem] = useState(true)
  const [nfts, setNFTs] = useState([])
  const [bigGrid, setBigGrid] = useState(false)
  const [activities, setActivities] = useState([])
  const [floorPrice, setFloorPrice] = useState(0)
  const [volume, setVolume] = useState(0)
  const [listedItems, setListedItems] = useState(0)
  const [sales, setSales] = useState([])
  const [token, setToken] = useState(null)
  const { showSidebar } = useContext(SidebarContext)
  const [filterDropdown, setFilterDropdown] = useState(false)

  useEffect(() => {
    const getTokenFromLocalStorage = async () => {
      const token = localStorage.getItem('token')
      setToken(token)
    }
    getTokenFromLocalStorage()
  }, [])

  useEffect(() => {
    // const collection = Collections.find((collection) => collection.id === parseInt(id))
    // setCollection(collection)
    // /nftCollection/{address}
    const res = fetch(`${BackendUrl}/nftCollection/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    res.then((res) => res.json()).then((data) => setCollection(data))
  }, [id, token])

  useEffect(() => {
    async function getFloorPrice() {
      const res = await fetch(`${BackendUrl}/floorPrice/${collection.nft_collection_address}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const data = await res.json()
      setFloorPrice(data.floor_price)
    }
    async function getVolume() {
      const res = await fetch(`${BackendUrl}/volume/${collection.nft_collection_address}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const data = await res.json()
      setVolume(data.volume)
    }
    async function getListedItems() {
      const res = await fetch(`${BackendUrl}/totalListedItems/${collection.nft_collection_address}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const data = await res.json()
      setListedItems(data.total_listed_items)
    }
    if (!collection || !token) {
      return
    }
    try {
      getFloorPrice()
      getVolume()
      getListedItems()
    } catch (error) {
      console.log(error)
    }
  }, [collection, token])

  useEffect(() => {
    let items
    if (collection) {
      const res = fetch(`${BackendUrl}/nfts/${collection.nft_collection_address}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      res
        .then((res) => res.json())
        .then((data) => {
          items = data
          if (items.error) {
            return
          }
          setNFTs(items)
          setActivities(Activities)
        })
    }
  }, [collection, token])
  useEffect(() => {
    // get sales
    async function getSales() {
      const res = await fetch(`${BackendUrl}/allSales`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const data = await res.json()
      setSales(data)
    }
    getSales()
    console.log(sales, 'sales')
  }, [token])

  return (
    <div className="pb-10">
      <ExtendedLayout>
        <BaseLayout>
          {collection && (
            <Collection collection={collection} floorPrice={floorPrice} volume={volume} listedItems={listedItems} />
          )}
        </BaseLayout>
        <div className="w-full relative md:mb-12 z-10">
          <div className="md:h-[200px] md:absolute w-full">
            <FilterBar
              filterDropdown={filterDropdown}
              setFilterDropdown={setFilterDropdown}
              item={item}
              setItem={setItem}
              setBigGrid={setBigGrid}
              bigGrid={bigGrid}
            />
          </div>
        </div>
        {item
          ? nfts.length > 0 &&
            (showSidebar || bigGrid ? (
              <BaseLayout noSidebar noTopbar>
                <div className="w-full flex justify-center">
                  <CollectionItems items={nfts} bigGrid={bigGrid} />
                </div>
              </BaseLayout>
            ) : (
              <div className="w-full flex pl-[110px] px-4">
                <CollectionItems items={nfts} bigGrid={bigGrid} fullWidth filterDropdown={filterDropdown} />
              </div>
            ))
          : sales && (
              <div className={`mt-5 relative ${filterDropdown ? 'ml-[170px]' : ''} `}>
                {sales.map((sale) => (
                  <CollectionActivity activity={sale} />
                ))}
              </div>
            )}
        {nfts.length === 0 && (
          <div className="mt-5 relative h-64 flex justify-center items-center">
            <div className="flex items-center justify-center">
              <span className="font-gilroy font-medium text-xl text-[#807373] dark:text-dark-gray">No NFTs found</span>
            </div>
          </div>
        )}
      </ExtendedLayout>
    </div>
  )
}
