async function getImageFromUri(uri) {
    try {
        console.log("uri in uri:", uri)
        const response = await fetch(uri); // Fetch the data from the URI
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        console.log(uri, 'uri')
        const metadata = await response.json(); // Parse the JSON response
        console.log(metadata,"metadata"); // Access the 'image' field
        return metadata.image;
    } catch (error) {
        console.error('Error fetching the image:', error);
    }
}

async function getNameFromUri(uri) {
    try {
        const response = await fetch(uri); // Fetch the data from the URI
        if (!response.ok) {
            throw new Error('Failed to fetch data');
            }
            console.log(response, 'response')
            const metadata = await response.json(); // Parse the JSON response
            console.log(metadata,"metadata"); // Access the 'image' field
            return metadata.name;
            } catch (error) {
                console.error('Error fetching the image:', error);
                }
                }

async function getDescriptionFromUri(uri) { 
    try {
        const response = await fetch(uri); // Fetch the data from the URI
        if (!response.ok) {
            throw new Error('Failed to fetch data');
            }
            const metadata = await response.json(); // Parse the JSON response
            console.log(metadata,"metadata"); // Access the 'image' field
            return metadata.description;
            } catch (error) {
                console.error('Error fetching the image:', error);
                }
                }


export {
    getImageFromUri,
    getNameFromUri,
    getDescriptionFromUri
}