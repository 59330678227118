const { Contract } = require('@ethersproject/contracts');
const { JsonRpcProvider } = require('@ethersproject/providers');
const { ethers } = require('ethers');
const ERC20_ABI = require('./abis/ERC20Abi.json');

// ERC-20 network configurations (example: add more networks as needed)
const networkConfigs = {
  159: {
    tokenContractAddress: '0xd53209b72131f86209812662d5eF14D2eecEa24E', // Example token contract
    marketplaceAddress: '0x2326d6a19D07800c5389d387aC7C12D10427A377', // Your marketplace contract address

  },
  158: {
    tokenContractAddress: '0x4A2328F316f932d0528F746c48195c76073E9762', // Example token contract
    marketplaceAddress: '0x9DD82308b219D6188d21dfF900C57140DB830093', // Your marketplace contract address
  }
  // Add more network configurations as needed
};

export default class ERC20Manager {
  constructor(network) {
    this.network = network;
    this.config = networkConfigs[network];
    this.marketplaceAddress = this.config.marketplaceAddress;

    if (!this.config) {
      throw new Error(`Network configuration for ${network} not found`);
    }
  }

  // Get the ERC-20 contract instance using ethers.js
  getContract(library) {
    const { tokenContractAddress } = this.config;
    console.log(ERC20_ABI, 'ERC20_ABI')
    return new Contract(tokenContractAddress, ERC20_ABI, library.getSigner());
  }

  // Approve token spending
  async allowance(ownerAddress, spenderAddress,library) {
    try {
      const contract = this.getContract(library);
      const allowance = await contract.allowance(ownerAddress, this.marketplaceAddress);
      console.log('Allowance:', allowance.toString());
      return allowance;
    }
    catch (error) {
      console.error('Error fetching allowance:', error);
      throw new Error('Error fetching allowance');
    }
  }
  async approve(library, spenderAddress, amount) {
    try {
      const contract = this.getContract(library);

      // Format the amount to Wei (assuming the token uses 18 decimals)
      const amountInWei = ethers.utils.parseUnits(amount.toString(), 18);

      // Call the approve method on the ERC-20 contract
      console.log(this.marketplaceAddress, 'this.marketplaceAddress')
      const tx = await contract.approve(this.marketplaceAddress, amountInWei);
      console.log(`Approval transaction sent: ${tx.hash}`);

      // Wait for the transaction to be confirmed
      const receipt = await tx.wait();
      console.log('Approval transaction confirmed:', receipt);
      // check allowance for the spender on marketplace contract

      return receipt;
    } catch (error) {
      console.error('Error during token approval:', error);
      throw error;
    }
  }
}

