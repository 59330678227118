import { BackendUrl } from './constants'

const { Contract } = require('@ethersproject/contracts')
const { JsonRpcProvider } = require('@ethersproject/providers')
const CollectionAbiRbaTestnet = require('./abis/CollectionAbiRbaTestnet.json')

const networkConfigs = {
  159: {
    abi: CollectionAbiRbaTestnet,
    factoryAddress: '0xDa70ee4a4b1474553eD86FD2841caf810B3cbb48',
  },
  158: {
    abi: CollectionAbiRbaTestnet,
    factoryAddress: '0xb41975DcD603bA00aF44e99a6B92bdE9b6165974',
  },
  // Add more network configurations as needed
  // anotherNetwork: {
  //     abi: AnotherAbi,
  //     factoryAddress: '0xAnotherFactoryAddress'
  // }
}

export default class CollectionManager {
  constructor(network) {
    this.network = network
    this.config = networkConfigs[network]

    if (!this.config) {
      throw new Error(`Network configuration for ${network} not found`)
    }
  }

  getContract(library) {
    const { abi, factoryAddress } = this.config
    return new Contract(factoryAddress, abi, library.getSigner())
  }
  async saveToBackend(name, symbol, baseURI, owner, proxyAddress, items, verified = true) {
    // get token from local storage
    const token = localStorage.getItem('token')
    if (!token) {
      throw new Error('Token not found')
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        name: name,
        symbol: symbol,
        image: baseURI,
        owner: owner,
        chain_id: this.network,
        nft_collection_address: proxyAddress,
        items: items,
        verified: verified,
      }),
    }
    console.log('url', `${BackendUrl}/nftCollection`)
    await fetch(`${BackendUrl}/nftCollection`, requestOptions)
      .then((response) => response.json())
      .then((data) => console.log(data))
  }

  async getCollections() {
    const token = localStorage.getItem('token')

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    console.log('url', `${BackendUrl}/allNftCollections`)
    let nfts = await fetch(`${BackendUrl}/allNftCollections`, requestOptions)
    nfts = await nfts.json()
    console.log(nfts)
    return nfts
  }
  async getCollectionsAccount(account) {
    const token = localStorage.getItem('token')
    if (!token) {
      throw new Error('Token not found')
    }
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    console.log('url', `${BackendUrl}/userNftCollections/${account}`)
    let nfts = await fetch(`${BackendUrl}/userNftCollections/${account}`, requestOptions)
    nfts = await nfts.json()
    console.log(nfts)
    return nfts
  }
  async getCollectionsAccount2(account) {
    const token = localStorage.getItem('token')
    if (!token) {
      throw new Error('Token not found')
    }
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    console.log('url', `${BackendUrl}/allNftCollections/${account}`)
    let nfts = await fetch(`${BackendUrl}/allNftCollections/${account}`, requestOptions)
    nfts = await nfts.json()
    console.log(nfts)
    return nfts
  }
  async getCollectionsAccount(account) {
    const token = localStorage.getItem('token')
    if (!token) {
      throw new Error('Token not found')
    }
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
    console.log('url', `${BackendUrl}/userNftCollections/${account}`)
    let nfts = await fetch(`${BackendUrl}/userNftCollections/${account}`, requestOptions)
    nfts = await nfts.json()
    console.log(nfts)
    return nfts
  }

  async deploy(name, symbol, baseURI, owner, paymentToken, library, items) {
    try {
      const contract = this.getContract(library)

      // Send the transaction and get the transaction hash
      const tx = await contract.createArborswapCollection(name, symbol, baseURI, owner, paymentToken)
      console.log('Transaction hash:', tx.hash)

      // Wait for the transaction to be mined
      const receipt = await tx.wait()
      console.log(receipt)

      // Extract the event from the transaction receipt
      const event = receipt.events.find((event) => event.event === 'ArborswapCollectionCreated')
      const proxyAddress = event.args.proxy // Get the proxy address from the event

      // Log the proxy address
      console.log('New collection proxy address:', proxyAddress)

      // Save the collection details to the backend, including the proxy address
      await this.saveToBackend(name, symbol, baseURI, owner, proxyAddress, items)
      return proxyAddress
    } catch (error) {
      console.error('Deployment failed:', error)
      throw error
    }
  }
}

// // Usage example
// const network = 'rbaTestnet'; // This could be 'rbaTestnet', 'anotherNetwork', etc.
// const CollectionManager = new CollectionManager(network);

// const deployContract = async () => {
//     try {
//         const tx = await CollectionManager.deploy('MyCollection', 'SYM', 'https://baseuri.com', '0xOwnerAddress', '0xPaymentTokenAddress');
//         await tx.wait(); // Optionally wait for the transaction to be mined
//         console.log('Contract deployed successfully');
//     } catch (error) {
//         console.error('Error during contract deployment:', error);
//     }
// };

// deployContract();
