const { Contract } = require('@ethersproject/contracts')
const { JsonRpcProvider } = require('@ethersproject/providers')
const { ethers } = require('ethers')
const MarketplaceAbi = require('./abis/MarketplaceAbi.json') // Your Marketplace ABI
const NFTabi = require('./abis/nftAbi.json')
const { toast } = require('react-toastify')
const { BackendUrl } = require('./constants')

const networkConfigs = {
  159: {
    abi: MarketplaceAbi, // Adding marketplace ABI for network 159
    marketplaceAddress: '0x2326d6a19D07800c5389d387aC7C12D10427A377', // Your marketplace contract address
  },
  158: {
    abi: MarketplaceAbi, // Adding marketplace ABI for network 159
    marketplaceAddress: '0x9DD82308b219D6188d21dfF900C57140DB830093', // Your marketplace contract address
  },
  // Add more network configurations as needed
}

export default class MarketplaceManager {
  constructor(network, library) {
    this.network = network
    this.config = networkConfigs[network]
    this.library = library
    this.token = localStorage.getItem('token')

    if (!this.config) {
      throw new Error(`Network configuration for ${network} not found`)
    }

    this.contract = new Contract(this.config.marketplaceAddress, this.config.abi, library.getSigner())
  }

  async approveNftTransfer(nftAddress, tokenId) {
    try {
      const nftContract = new Contract(nftAddress, NFTabi, this.library.getSigner())
      const tx = await nftContract.approve(this.config.marketplaceAddress, tokenId)
      await tx.wait()
      console.log('NFT approved for marketplace:', tokenId)
    } catch (error) {
      console.error('Failed to approve NFT for marketplace:', error)
      throw error
    }
  }

  // Add ERC20 token to marketplace
  async addERC20Token(erc20TokenAddress) {
    try {
      const tx = await this.contract.addERC20tokens(erc20TokenAddress)
      await tx.wait()
      console.log('ERC20 token added:', erc20TokenAddress)
    } catch (error) {
      console.error('Failed to add ERC20 token:', error)
    }
  }

  // Add item to the marketplace and save it to the backend
  async addItemToMarket(tokenId, tokenAddress, askingPrice, priceInWei, bidItem, tokenERC20) {
    try {
      console.log(tokenId, tokenAddress, askingPrice, bidItem, tokenERC20)
      // get approval
      if (!this.token) {
        throw new Error('Token not found')
      }

      await this.approveNftTransfer(tokenAddress, tokenId)

      const tx = await this.contract.addItemToMarket(tokenId, tokenAddress, priceInWei, bidItem, tokenERC20)
      const receipt = await tx.wait()
      const newItemId = receipt.events[0].args[0].toNumber()
      console.log('Item added to market:', newItemId)

      // Save item to backend
      const response = await fetch(`${BackendUrl}/nftListing`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
        body: JSON.stringify({
          item_id: newItemId,
          token_id: tokenId,
          token_address: tokenAddress,
          asking_price: askingPrice.toString(),
          seller: await this.contract.signer.getAddress(),
          listed_at: new Date().toISOString(),
          status: 'available',
          bidding: bidItem,
        }),
      })

      if (!response.ok) {
        throw new Error('Failed to save item to the backend')
      }

      return newItemId
    } catch (error) {
      throw new Error('Failed to add item to market')
      console.error('Failed to add item to market:', error)
    }
  }

  // Buy an item and save the sale to the backend
  async buyItem(itemId, price, tokenAddress, tokenId) {
    try {
      if (!this.token) {
        throw new Error('Token not found')
      }
      const tx = await this.contract.buyItem(itemId, { value: price })
      await tx.wait()
      console.log('Item purchased:', itemId)

      // Save sale to backend
      const response = await fetch(`${BackendUrl}/sale`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
        body: JSON.stringify({
          nft_collection_address: tokenAddress,
          item_id: itemId,
          buyer: await this.contract.signer.getAddress(),
          price: price.toString(),
          transaction_hash: tx.hash,
          sold_at: new Date().toISOString(),
          token_address: tokenAddress,
          token_id: parseInt(tokenId),
        }),
      })

      if (!response.ok) {
        throw new Error('Failed to save sale to the backend')
      }
    } catch (error) {
      console.error('Failed to purchase item:', error)
    }
  }

  // Place a bid on an auction and save the bid to the backend
  async placeABid(auctionItemId, bidAmount, tokenAddress) {
    try {
      // convert bidAm,ount to big number not in wei
      if (!this.token) {
        throw new Error('Token not found')
      }
      const bidAmountInWei = ethers.utils.parseEther(bidAmount.toString())

      const tx = await this.contract.placeABid(auctionItemId, bidAmount, { value: bidAmount })
      await tx.wait()
      console.log('Bid placed on item:', auctionItemId)

      // Save bid to backend
      const response = await fetch(`${BackendUrl}/bid`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
        body: JSON.stringify({
          item_address: tokenAddress,
          auction_item_id: auctionItemId,
          bidder: await this.contract.signer.getAddress(),
          bid_amount: bidAmount.toString(),
          bid_time: new Date().toISOString(),
          auction_status: 'active',
        }),
      })

      if (!response.ok) {
        toast.error('Failed to place a bid')
      }
    } catch (error) {
      // throw
      console.error('Failed to place a bid:', error)
      throw new Error('Failed to place a bid')
    }
  }

  // End auction and update auction status in the backend
  async endAuction(auctionItemId, tokenAddress, tokenId, price) {
    try {
      // const tx = await this.contract.endAuction(auctionItemId, { value: ethers.utils.parseEther('0.01') })
      // await tx.wait()
      if (!this.token) {
        throw new Error('Token not found')
      }
      console.log('Auction ended:', auctionItemId)

      // save sale to backend
      const response = await fetch(`${BackendUrl}/sale`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
        body: JSON.stringify({
          nft_collection_address: tokenAddress,
          item_id: auctionItemId,
          buyer: await this.contract.signer.getAddress(),
          price: price.toString(),
          transaction_hash: '0x15ef4132e22c081fbbfe9f71415905692a58d849cbb51f8f22879c5fd0e1b681',
          sold_at: new Date().toISOString(),
          token_address: tokenAddress,
          token_id: parseInt(tokenId),
        }),
      })

      if (!response.ok) {
        throw new Error('Failed to update auction status in the backend')
      }
    } catch (error) {
      console.error('Failed to end auction:', error)
    }
  }

  // Fetch items for sale
  async fetchItemsForSale(itemId) {
    try {
      const item = await this.contract.itemsForSale(itemId)
      console.log('Item for sale:', item)
      return item
    } catch (error) {
      console.error('Failed to fetch item:', error)
    }
  }

  // Change marketplace fee address
  async changeFeeAddress(newFeeAddress) {
    try {
      const tx = await this.contract.changeFeeAddress(newFeeAddress)
      await tx.wait()
      console.log('Fee address changed to:', newFeeAddress)
    } catch (error) {
      console.error('Failed to change fee address:', error)
    }
  }

  // Change service fee
  async changeServiceFee(newFee) {
    try {
      const tx = await this.contract.changeServiceFee(newFee)
      await tx.wait()
      console.log('Service fee changed to:', newFee)
    } catch (error) {
      console.error('Failed to change service fee:', error)
    }
  }

  // Get service fee
  async getServiceFee() {
    try {
      const fee = await this.contract.serviceFee()
      console.log('Current service fee:', fee.toString())
      return fee
    } catch (error) {
      console.error('Failed to get service fee:', error)
    }
  }
}

// Usage Example
/*
const provider = new JsonRpcProvider('https://rpc.url'); // Example provider
const library = provider; // Could also be a Web3 library connected wallet
const marketplaceManager = new MarketplaceManager(159, library);

// Example call to add an item to the marketplace
marketplaceManager.addItemToMarket(
  1, 
  '0xTokenAddressHere', 
  ethers.utils.parseEther('1'), 
  false, 
  '0xERC20AddressHere'
);
*/
