import * as React from 'react'

const MenuSVG = (props) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.4}
      d="M20 6.52V1.98C20 .57 19.36 0 17.77 0h-4.04c-1.59 0-2.23.57-2.23 1.98v4.53c0 1.42.64 1.98 2.23 1.98h4.04C19.36 8.5 20 7.93 20 6.52Z"
    />
    <path d="M20 17.77v-4.04c0-1.59-.64-2.23-2.23-2.23h-4.04c-1.59 0-2.23.64-2.23 2.23v4.04c0 1.59.64 2.23 2.23 2.23h4.04c1.59 0 2.23-.64 2.23-2.23ZM8.5 6.52V1.98C8.5.57 7.86 0 6.27 0H2.23C.64 0 0 .57 0 1.98v4.53c0 1.42.64 1.98 2.23 1.98h4.04c1.59.01 2.23-.56 2.23-1.97Z" />
    <path
      opacity={0.4}
      d="M8.5 17.77v-4.04c0-1.59-.64-2.23-2.23-2.23H2.23C.64 11.5 0 12.14 0 13.73v4.04C0 19.36.64 20 2.23 20h4.04c1.59 0 2.23-.64 2.23-2.23Z"
    />
  </svg>
)

export default MenuSVG
