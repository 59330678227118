import React, { useEffect, useState } from 'react'
import LinkArrowSVG from '../../svgs/link_arrow'
import Details from './SubComponents/Details'
import NftActivityTab from './SubComponents/NftActivityTab'
import { useDefaultChainId } from '../../config/useDefaultChainId'

export default function NftActivity({ nft, allBids, tokenId, sales ,token }) {
  const [activities, setActivities] = useState()
  const [activeTab, setActiveTab] = useState('activity')
  const [info, setInfo] = useState(nft)
  const chain_id = useDefaultChainId()

  return (
    <div className="w-full flex flex-col justify-center bg-white dark:bg-black-shade2-background rounded-[20px] mt-2 md:mt-10">
      <div className="h-12 mt-2 flex w-full justify-around">
        <div
          className={` h-full w-14 md:w-[84px] flex items-center justify-center ml-4 ${
            activeTab === 'info' ? 'border-b-2 border-primary-green border-opacity-50' : null
          }`}
          onClick={() => setActiveTab('info')}
        >
          <span
            className={`font-gilroy cursor-pointer font-bold ${
              activeTab === 'info'
                ? 'text-primary-green'
                : 'dark:text-light-text text-dark-text-color hover:text-primary-green'
            }`}
          >
            Info
          </span>
        </div>

        <div
          className={` h-full w-14 md:w-[84px] flex items-center justify-center ml-4 ${
            activeTab === 'bids' ? 'border-b-2 border-primary-green border-opacity-50' : null
          }`}
          onClick={() => setActiveTab('bids')}
        >
          <span
            className={`font-gilroy cursor-pointer font-bold ${
              activeTab === 'bids'
                ? 'text-primary-green'
                : ' dark:text-light-text text-dark-text-color hover:text-primary-green'
            }`}
          >
            Bids
          </span>
        </div>

        <div
          className={` h-full w-14 md:w-[84px] flex items-center justify-center ml-4 ${
            activeTab === 'activity' ? 'border-b-2 border-primary-green border-opacity-50' : null
          }`}
          onClick={() => setActiveTab('activity')}
        >
          <span
            className={`font-gilroy cursor-pointer font-bold ${
              activeTab === 'activity'
                ? 'text-primary-green'
                : 'dark:text-light-text text-dark-text-color hover:text-primary-green'
            }`}
          >
            Activity
          </span>
        </div>
      </div>

      <div className="mt-4 mb-4">
        {allBids &&
          activeTab === 'bids' &&
          allBids?.map((activity, index) => <NftActivityTab activity={activity} key={index} action={'bid'} token={token}/>)}
        {sales &&
          activeTab === 'activity' &&
          sales?.map((activity, index) => <NftActivityTab activity={activity} key={index} action={'sold'} sale={true} token={token}/>)}

        {info && activeTab === 'info' && (
          <div className="flex flex-col px-5 py-5">
            <span className="font-gilroy font-bold dark:text-[#807373] text-dark-gray">Properties</span>

            {/* <div className="grid grid-cols-3 gap-5 mt-5">
              {info.map((info) =>
                info.info.map((my_info, index) => (
                  <div
                    key={index}
                    className="flex flex-col bg-[#FAF8F5] dark:bg-black-shade3-background rounded-md p-[14px]"
                  >
                    <span className="text-sm font-medium font-gilroy text-light-text dark:text-dark-text-color">
                      {my_info.name}
                    </span>

                    <span className="mt-[6px] font-gilroy text-sm font-semibold text-[#807373] dark:text-dark-gray">
                      {my_info.type}
                    </span>

                    <span className="mt-[6px] text-sm font-medium font-gilroy text-[#C89211]">{my_info.rarity}</span>
                  </div>
                )),
              )}
            </div> */}

            <span className="mt-10 font-gilroy font-bold text-[#807373] dark:text-dark-gray">Details</span>
            <Details detail={'Contract Address'} value={nft.nft_address} />
            <Details detail={'Token ID'} value={tokenId} />
            <Details detail={'Token Standard'} value={'ERC-721'} />
            <Details detail={'Chain Type'} value={chain_id} img={nft.chain} />
            <Details detail={'Creator Royalties'} value={nft.royalties} />
          </div>
        )}
      </div>
    </div>
  )
}
