import * as React from 'react'

const MenuSmallSVG = (props) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.4}
      d="M12.496 4.74V2.066c0-.83-.376-1.166-1.311-1.166H8.808c-.935 0-1.312.336-1.312 1.166v2.668c0 .837.377 1.166 1.312 1.166h2.377c.935.006 1.311-.33 1.311-1.16Z"
    />
    <path d="M12.496 11.34V8.666c0-.83-.376-1.166-1.311-1.166H8.808c-.935 0-1.312.336-1.312 1.166v2.668c0 .836.377 1.166 1.312 1.166h2.377c.935.006 1.311-.33 1.311-1.16Z" />
    <path
      opacity={0.4}
      d="M12.496 17.94v-2.674c0-.83-.376-1.166-1.311-1.166H8.808c-.935 0-1.312.335-1.312 1.166v2.667c0 .837.377 1.166 1.312 1.166h2.377c.935.006 1.311-.33 1.311-1.16Z"
    />
    <path d="M19.104 4.74V2.066c0-.83-.377-1.166-1.312-1.166h-2.377c-.935 0-1.311.336-1.311 1.166v2.668c0 .837.376 1.166 1.311 1.166h2.377c.935.006 1.311-.33 1.311-1.16Z" />
    <path
      opacity={0.4}
      d="M19.104 11.34V8.666c0-.83-.377-1.166-1.312-1.166h-2.377c-.935 0-1.311.336-1.311 1.166v2.668c0 .836.376 1.166 1.311 1.166h2.377c.935.006 1.311-.33 1.311-1.16Z"
    />
    <path d="M19.104 17.94v-2.674c0-.83-.377-1.166-1.312-1.166h-2.377c-.935 0-1.311.335-1.311 1.166v2.667c0 .837.376 1.166 1.311 1.166h2.377c.935.006 1.311-.33 1.311-1.16ZM5.896 4.74V2.066C5.896 1.236 5.52.9 4.585.9H2.208C1.273.9.896 1.236.896 2.066v2.668c0 .837.377 1.166 1.312 1.166h2.377c.935.006 1.311-.33 1.311-1.16Z" />
    <path
      opacity={0.4}
      d="M5.896 11.34V8.666c0-.83-.376-1.166-1.311-1.166H2.208c-.935 0-1.312.336-1.312 1.166v2.668c0 .836.377 1.166 1.312 1.166h2.377c.935.006 1.311-.33 1.311-1.16Z"
    />
    <path d="M5.896 17.94v-2.674c0-.83-.376-1.166-1.311-1.166H2.208c-.935 0-1.312.335-1.312 1.166v2.667c0 .837.377 1.166 1.312 1.166h2.377c.935.006 1.311-.33 1.311-1.16Z" />
  </svg>
)

export default MenuSmallSVG
