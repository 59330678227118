import React, { useState, useEffect } from 'react'
import Carousel from '../Home/Slider/Slider'
import Button from '../Home/SubComponents/Button'
import { Buttons, Tabs, Days } from '../../data/components'
import Tab from '../Home/SubComponents/Tab'
import ListCard from '../Home/Collections/ListCard'
import MobileCard from './MobileCard'
import { BackendUrl } from '../../config/constants' // Assuming you have this URL configured
import { useModal } from 'react-simple-modal-provider'
export default function CollectionRankingBase() {
  const [activeTab, setActiveTab] = useState(1)
  const [activeDay, setActiveDay] = useState(1)
  const [collections, setCollections] = useState([]) // To store fetched collections
  const [token, setToken] = useState(null)
  const { open: openLoadingModal, close: closeLoadingModal } = useModal('LoadingModal')

  useEffect(() => {
    const getTokenFromLocalStorage = async () => {
      const token = localStorage.getItem('token')
      setToken(token)
    }
    getTokenFromLocalStorage()
  }, [])

  // Fetch collection rankings from the backend
  useEffect(() => {
    const fetchCollections = async () => {
      openLoadingModal()
      try {
        const response = await fetch(`${BackendUrl}/nftCollectionRankings`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        })

        const data = await response.json()
        setCollections(data) // Assuming the response returns the collection data
        closeLoadingModal()
      } catch (error) {
        console.error('Error fetching collections:', error)
        closeLoadingModal()
      }
    }
      fetchCollections()
  }, [token]) // Runs once when the component mounts

  return (
    <div className="px-[3%] md:pl-[5%] md:pr-[3%] pb-[2%] bg-tree-pattern bg-center bg-no-repeat bg-white dark:bg-black-shade2-background">
      <div className="flex w-full pt-16">
        <div className="hero-text w-full flex flex-col md:w-[30%]">
          <div className="main-text flex items-end">
            <span className="font-gilroy font-bold text-[31px] text-dark-text dark:text-dark-white-color">
              Top Ranked <br />
              Collections
            </span>
          </div>
          <div className="sub-text mt-2">
            <span className="font-gilroy font-medium text-[#807373] dark:text-dark-gray">
              Explore and Discover collections and NFTs.
            </span>
          </div>
        </div>
        <div className="hidden md:block ml-10 md:w-[70%] slider">
          <Carousel />
        </div>
      </div>

      {/* Tabs for days (Mobile view) */}
      <div className="flex md:hidden w-full items-center justify-between pr-4">
        <div className="tabs">
          <div className="flex items-center p-1 bg-[#F5F1EB] dark:bg-black-shade4-background">
            {Days.map((day) => (
              <Tab
                key={day.id}
                tabName={day.mobileOption}
                active={day.id === activeDay}
                setActiveTab={setActiveDay}
                id={day.id}
              />
            ))}
          </div>
        </div>
        <Button key={Buttons[1].id} buttonText={Buttons[1].buttonText} dropDownItems={Buttons[1].dropDownItems} />
      </div>

      {/* Tabs for the collections and buttons */}
      <div className="hidden md:flex w-full items-center mt-12 justify-between">
        <div className="tabs">
          <div className="flex items-center p-1 bg-[#F5F1EB] dark:bg-black-shade4-background">
            {Days.map((day) => (
              <Tab
                key={day.id}
                tabName={day.option}
                active={day.id === activeDay}
                setActiveTab={setActiveDay}
                id={day.id}
              />
            ))}
          </div>
        </div>

        <Button key={Buttons[1].id} buttonText={Buttons[1].buttonText} dropDownItems={Buttons[1].dropDownItems} />

        <div className="tabs">
          <div className="flex items-center p-1 bg-[#F5F1EB] dark:bg-black-shade4-background">
            {Tabs.map((tab) => (
              <Tab
                key={tab.id}
                tabName={tab.tabName}
                active={tab.id === activeTab}
                setActiveTab={setActiveTab}
                id={tab.id}
              />
            ))}
          </div>
        </div>
      </div>

      {/* List of collections (Desktop view) */}
      <div className="hidden md:block">
        {collections.map((card, index) => (
          <ListCard
            key={card.id}
            card_id={card.id}
            rank={index + 1} // Assuming rank is the order in the list
            card_name={card.name}
            verified={card.verified}
            card_icon={card.icon}
            card_category_icon={'/images/home/collections/cards/binance.svg'}
            floor_price={card.floor_price}
            item_quantity={card.item_quantity}
            volume={card.volume}
            change={card.change}
          />
        ))}
      </div>

      {/* List of collections (Mobile view) */}
      <div className="md:hidden px-4 mt-8">
        {collections.map((card, index) => (
          <MobileCard
            key={card.id}
            rank={index + 1}
            card_name={card.name}
            item_quantity={card.item_quantity}
            card_icon={card.icon}
            card_category_icon={'/images/home/collections/cards/binance.svg'}
            floor_price={card.floor_price}
            volume={card.volume}
          />
        ))}
      </div>
    </div>
  )
}
