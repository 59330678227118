const { Contract } = require('@ethersproject/contracts')
const { JsonRpcProvider } = require('@ethersproject/providers')
const { formatBigToNum } = require('../utils/numberFormat')
const NftAbi = require('./abis/nftAbi.json')
const { BackendUrl } = require('./constants')

const networkConfigs = {
  159: {
    abi: NftAbi,
    nftContractAddress: '0x13Aa592272Bf895bF7E59ec6b2e7dc43C4F0a1B7',
  },
  158: {
    abi: NftAbi,
    nftContractAddress: '0x13Aa592272Bf895bF7E59ec6b2e7dc43C4F0a1B7',
  },
  // Add more network configurations as needed
}

export default class NFTManager {
  constructor(network) {
    this.network = network
    this.config = networkConfigs[network]
    this.token = localStorage.getItem('token')

    if (!this.config) {
      throw new Error(`Network configuration for ${network} not found`)
    }
  }

  getContract(library, proxy) {
    const { abi, nftContractAddress } = this.config
    return new Contract(proxy, abi, library.getSigner())
  }

  async getTokenURI(formData) {
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`)
    }
    const requestOptions = {
      method: 'POST',
      body: formData,
    }

    try {
      const response = await fetch(`${BackendUrl}/metadata`, requestOptions)
      const data = await response.json()
      console.log(data, 'data')
      if (!data.metadata) {
        throw new Error('Token URI not returned from the backend')
      }

      console.log('Token URI:', data.metadata)
      const tokenUri = BackendUrl + data.metadata
      return tokenUri
    } catch (error) {
      console.error('Error fetching Token URI:', error)
      throw error
    }
  }

  async saveToBackend(tokenId, recipient, collectionAddress, nft, uri) {
    if (!this.token) {
      throw new Error('Token not found')
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${this.token}` },
      body: JSON.stringify({
        token_id: tokenId,
        recipient: recipient,
        nft_collection_address: collectionAddress,
        nft_address: collectionAddress,
        copies: nft.copies === '' ? 1 : nft.copies,
        royalties: nft.royalties === '' ? '0%' : nft.royalties,
        uri: uri,
      }),
    }

    // /nft of main backend
    console.log(BackendUrl + '/nft')
    await fetch(`${BackendUrl}/nft`, requestOptions)
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error(error))
      .finally(() => console.log('NFT saved to backend'))
  }

  async mintNFT(library, recipient, collectionAddress, formData, nft, copies) {
    try {
      // Step 1: Get the Token URI from the backend
      const tokenURI = await this.getTokenURI(formData)

      // Step 2: Get the contract and call the mintNFT function
      const contract = this.getContract(library, collectionAddress)
      console.log(recipient, tokenURI, collectionAddress)
      const formattedRoyalties = nft.royalties.replace('%', '')
      const tx = await contract.mint(copies, recipient, formattedRoyalties)

      console.log('Minting transaction hash:', tx.hash)

      // Step 3: Wait for the transaction to be mined
      const receipt = await tx.wait()
      console.log('NFT Minted with receipt:', receipt)

      const mintedTokenId = formatBigToNum(receipt.events[0].args[2], 18)
      console.log('Minted Token ID:', mintedTokenId)

      // You can call a saveToBackend function here, which you will implement later
      // this.saveMintedNFTToBackend(mintedTokenId, recipient, collectionAddress, metadataDetails);
      await this.saveToBackend(mintedTokenId, recipient, collectionAddress, nft, tokenURI)

      return mintedTokenId
    } catch (error) {
      console.error('Minting failed:', error)
      throw error
    }
  }
}
