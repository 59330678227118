import * as React from 'react'

const HeartTickSVG = (props) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14.334 10.334c-2.209 0-4 1.792-4 4 0 .75.208 1.458.583 2.058a3.973 3.973 0 0 0 3.417 1.942 3.963 3.963 0 0 0 3.416-1.942c.367-.6.584-1.308.584-2.058 0-2.208-1.792-4-4-4Zm1.983 3.475-2.133 1.967a.636.636 0 0 1-.425.166.618.618 0 0 1-.442-.183l-.983-.983a.629.629 0 0 1 0-.884.629.629 0 0 1 .883 0l.558.559 1.692-1.567a.63.63 0 0 1 .883.033.64.64 0 0 1-.033.892Z" />
    <path d="M18.333 7.276a8.9 8.9 0 0 1-.433 2.758c-.05.175-.258.225-.409.116a5.289 5.289 0 0 0-3.158-1.033 5.26 5.26 0 0 0-5.25 5.25c0 .9.233 1.784.675 2.567.134.233-.025.533-.275.441-2.008-.683-6.067-3.175-7.383-7.341a8.9 8.9 0 0 1-.433-2.758C1.667 4.7 3.74 2.617 6.3 2.617c1.508 0 2.858.734 3.7 1.859a4.624 4.624 0 0 1 3.7-1.859c2.558 0 4.633 2.084 4.633 4.659Z" />
  </svg>
)

export default HeartTickSVG
