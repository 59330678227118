import React, { useRef, useState } from 'react'
import UploadSVG from '../../svgs/upload'
import HeadingTags from '../Common/HeadingTags'
import ImagePlaceholder from './Subcomponents/ImagePlaceholder'
import CollectionManager from '../../config/collection'
import { useEthers } from '@usedapp/core'
import { useDefaultChainId } from '../../config/useDefaultChainId'
import { useModal } from 'react-simple-modal-provider'

export default function CreateCollections({ setVisibility }) {
  const [collection, setCollection] = useState({})
  const [image, setImage] = useState(null)
  const [name, setName] = useState('')
  const [symbol, setSymbol] = useState('')
  const [baseURI, setBaseURI] = useState('')
  const { open: openLoadingModal, close: closeLoadingModal } = useModal('LoadingModal')

  const { library, account } = useEthers()
  const chainId = useDefaultChainId()
  const paymentToken = '0x0000000000000000000000000000000000000000'

  const handleSubmit = async () => {
    setCollection({
      name: name,
      symbol: symbol,
      baseURI: baseURI,
      owner: account,
      paymentToken: paymentToken,
    })
    openLoadingModal()
    const collectionManager = new CollectionManager(chainId)
    await collectionManager.deploy(name, symbol, baseURI, account, paymentToken, library, 1)
    setTimeout(() => {
      closeLoadingModal()
      window.location.reload()
      setVisibility(false)
    }, 2000)
  }

  return (
    <div className="w-full md:w-1/2 ">
      <div className="bg-white dark:bg-black-shade2-background">
        <div className="flex items-center rounded-t-[10px] border border-opacity-50 border-[#D7D9DD] dark:border-black-shade4-background justify-between py-6 px-5 ">
          <span className="text-xl font-gilroy font-bold text-dark-text dark:text-dark-white-color">
            Create a Collection
          </span>

          <div className="flex items-center cursor-pointer" onClick={() => setVisibility(false)}>
            <span className="text-sm font-gilroy font-semibold text-dark-text dark:text-dark-white-color mr-2">
              Close
            </span>
            <div className="flex justify-center items-center bg-[#E56060] text-[#E56060] bg-opacity-10 rounded-full w-[15px] h-[15px]">
              &#10005;
            </div>
          </div>
        </div>

        <div className="mt-4 py-6 px-5">
          <div className="mt-10">
            <HeadingTags name="Name" required />

            <input
              className="bg-transparent w-full mt-5 px-5 py-4 font-gilroy placeholder:font-medium dark:placeholder:text-light-text placeholder:text-dark-text font-semibold text-dark-text dark:text-dark-white-color focus:outline-none border-[1.5px] rounded-lg border-light-text border-opacity-50"
              type={'text'}
              placeholder="Enter Name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="mt-10">
            <HeadingTags name="Symbol" />

            <input
              className="bg-transparent w-full px-5 py-4 mt-5 font-gilroy placeholder:font-medium dark:placeholder:text-light-text placeholder:text-dark-text font-semibold text-dark-text dark:text-dark-white-color focus:outline-none border-[1.5px] rounded-lg border-light-text border-opacity-50"
              type={'text'}
              placeholder="Enter Symbol"
              onChange={(e) => setSymbol(e.target.value)}
            ></input>
          </div>

          <div className="mt-10">
            <HeadingTags name="Image URI" />

            <input
              className="bg-transparent w-full px-5 py-4 mt-5 font-gilroy placeholder:font-medium dark:placeholder:text-light-text placeholder:text-dark-text font-semibold text-dark-text dark:text-dark-white-color focus:outline-none border-[1.5px] rounded-lg border-light-text border-opacity-50"
              type={'text'}
              placeholder="Enter Image URI"
              onChange={(e) => setBaseURI(e.target.value)}
            ></input>
          </div>
        </div>
      </div>

      <button
        className="bg-primary-green text-white font-gilroy font-bold text-xl py-4 mt-4 rounded-lg w-full"
        onClick={handleSubmit}
      >
        Create Collection
      </button>
    </div>
  )
}
