import WebRouter from './route'
import { BrowserRouter as Router } from 'react-router-dom'
import { SidebarProvider } from './context/SidebarContext/GlobalProvider'
import { ModalProvider } from 'react-simple-modal-provider'
import modals from './Modal/index'
import { DAppProvider } from '@usedapp/core'
import { useDefaultChainId } from './config/useDefaultChainId'
import { getNetworkConfig } from './config/networks'
function App() {
  // const defaultChainId = useDefaultChainId()
  // console.log(defaultChainId, 'defaultChainId')
  const config = getNetworkConfig(158)

  return (
    <div className=" dark:bg-dark-background">
      <DAppProvider config={config}>
        <ModalProvider value={modals}>
          <SidebarProvider>
            <Router>
              <WebRouter />
            </Router>
          </SidebarProvider>
        </ModalProvider>
      </DAppProvider>
    </div>
  )
}

export default App
