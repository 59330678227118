import React, { useEffect, useState ,useContext} from 'react'
import AddDetails from './AddDetails'
import CreateCollections from './CreateCollections'
import Preferences from './Preferences'
import Preview from './Preview'
import { BackendUrl } from '../../config/constants'
import { useEthers } from '@usedapp/core'
import { toast } from 'react-toastify'
import { ThemeContext } from '../../context/ThemeContext/ThemeProvider'; // Import your ThemeContext


const panel_items = [
  {
    id: 1,
    name: 'Preferences',
  },
  {
    id: 2,
    name: 'Add Details',
  },
  {
    id: 3,
    name: 'Preview',
  },
]

export default function Base() {
  const [active, setActive] = useState('Preferences')
  const [nftType, setNftType] = useState(0)
  const [nftChainType, setNftChainType] = useState(0)
  const [nftObject, setNftObject] = useState({})
  const [visible, setVisible] = useState(false)
  const { account } = useEthers()
  const { theme } = useContext(ThemeContext); // Get the current theme


  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)

  useEffect(() => {
    const getTokenFromLocalStorage = async () => {
      const token = localStorage.getItem('token')
      setToken(token)
    }
    getTokenFromLocalStorage()
  }, [])


  useEffect(() => {
    async function getUser() {
      const response = await fetch(`${BackendUrl}/user/${account}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        }
      })
      const data = await response.json()
      console.log(data)
      if (data.detail === 'User not found') {
        return
      }
      setUser(data)
    }
    if (account && token) {
    getUser()
    }
  }, [account, token])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [visible])
  const handleNextPreferences = () => {
    if (user) {
      if (!user.profile_picture || !user.cover_image || !user.name) {
        toast.error('You need to complete your profile first')
        return
      }
      setActive('Add Details')
    } else {
      toast.error('You need to complete your profile first')
      return
    }
  }

  return (
    <div className="">
      {visible && (
        <div className="fixed top-[5%] w-full md:w-3/4 flex bg-transparent backdrop-blur-md justify-center z-20 h-full">
          <CreateCollections setVisibility={setVisible} />
        </div>
      )}
     <div
  className="flex flex-col w-full bg-light-gray-shade md:flex-row"
  style={{ background: theme === 'dark' ? '#202226' : 'inherit' }} // Change background based on theme
>
        <div className="panel flex py-4 md:py-0 justify-around md:block md:pl-9 md:pr-[5%] rounded-l-[10px] bg-light-gray-shade dark:bg-black-shade3-background">
          {panel_items.map((item) => (
            <div key={item.id} className={`panel-item md:mt-9 flex items-center`}>
              {item.name === active && <div className={`w-2 h-2 rounded-full bg-primary-green mr-[10px]`} />}
              <span
                className={`text-sm font-gilroy font-semibold ${
                  active === item.name
                    ? 'text-dark-text dark:text-dark-white-color'
                    : 'text-light-text dark:text-dark-text-color'
                }`}
              >
                {item.name}
              </span>
            </div>
          ))}
        </div>

        <div className="panel-content bg-white dark:bg-black-shade2-background rounded-r-[10px] px-4 md:px-9 md:w-2/3">
          {active === 'Preferences' && (
            <Preferences
              setActive={setActive}
              type={nftType}
              setType={setNftType}
              chainType={nftChainType}
              setChainType={setNftChainType}
              handleNextPreferences={handleNextPreferences}
            />
          )}
          {active === 'Add Details' && (
            <AddDetails
              setActive={setActive}
              nftType={nftType}
              nft={nftObject}
              setNft={setNftObject}
              setVisible={setVisible}
            />
          )}
          {active === 'Preview' && (
            <Preview nft={nftObject} nftType={nftType} nftChainType={nftChainType} setActive={setActive} />
          )}
        </div>
      </div>
    </div>
  )
}
