import React, { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import BaseLayout from '../components/BaseLayout/BaseLayout'
import CollectionItems from '../components/Collection/CollectionItems'
import FilterBar from '../components/Collection/FilterBar'
import CoverImage from '../components/Common/CoverImage'
import ExtendedLayout from '../components/ExtendedLayout/ExtendedLayout'
import ProfileBase from '../components/UserProfile'
import CollectionManager from '../config/collection'
import { useDefaultChainId } from '../config/useDefaultChainId'
import { SidebarContext } from '../context/SidebarContext/GlobalProvider'
import { NFTs } from '../data/cards'
import Card from '../components/Home/Collections/Card'
import ListCard from '../components/Home/Collections/ListCard'
import { useEthers } from '@usedapp/core'
import { BackendUrl } from '../config/constants'
import { Link } from 'react-router-dom'
const profile = {
  id: 1,
  name: 'Three Dee',
  image: '/images/user_profile/cover.svg',
  dp: '/images/nft/creator.svg',
  verified: true,
  description:
    'A deflationary collection of degenerates, punks, and misfits. Gods of the metaverse & masters of our own universe.',
  contact_address: '0xc19....2dde',
}

export default function ProfilePage() {
  const { showSidebar } = useContext(SidebarContext)
  const [active, setActive] = useState(1)
  const [bigGrid, setBigGrid] = useState(false)
  const [filterDropdown, setFilterDropdown] = useState(false)
  const [collections, setCollections] = useState([])
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)
  const [searchQuery, setSearchQuery] = useState('') // Add search state
  const chain_id = useDefaultChainId()
  const { account } = useEthers()
  const { address } = useParams()

  useEffect(() => {
    const getTokenFromLocalStorage = async () => {
      const token = localStorage.getItem('token')
      setToken(token)
    }
    getTokenFromLocalStorage()
  }, [])
  useEffect(() => {
    async function getCollections(account) {
      const collectionManager = new CollectionManager(chain_id)
      const collectionsRes = await collectionManager.getCollectionsAccount(account)
      // remove those which dont have any items, owner is unknown or image is empty
      const filteredCollections = collectionsRes.filter(
        (collection) =>
          collection.items > 0 &&
          collection.owner !== 'Unknown' &&
          collection.image !== '' &&
          collection.image !== 'Unknown',
      )
      setCollections(filteredCollections)
    }
    if (account && !address) {
      getCollections(account)
    } else if (address) {
      getCollections(address)
    }
  }, [account, address])

  const filteredCollections = collections
    // Filter out duplicates based on nft_collection_address
    .filter(
      (collection, index, self) =>
        index === self.findIndex((c) => c.nft_collection_address === collection.nft_collection_address),
    )
    // Apply search query filter if needed
    .filter((collection) => collection.name.toLowerCase().includes(searchQuery.toLowerCase()))
  useEffect(() => {
    async function getUser(account) {
      const response = await fetch(`${BackendUrl}/user/${account}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const data = await response.json()
      console.log(data)
      if (data.detail === 'User not found') {
        return
      }
      setUser(data)
    }
    if (account && token && !address) {
      getUser(account)
    } else if (address) {
      getUser(address)
    }
  }, [account])
  return (
    <div className="pb-10 bg-white dark:bg-black-shade2-background">
      <ExtendedLayout>
        <BaseLayout onSearch={setSearchQuery}>
          <CoverImage full_image={BackendUrl + '/' + user?.cover_image} name={user?.name} />

          <ProfileBase profile={user} isAddress={address ? true : false} />
        </BaseLayout>
      </ExtendedLayout>
      <div className="w-full relative md:mb-12 z-10">
        <div className="md:h-[200px] md:absolute w-full">
          <FilterBar
            filterDropdown={filterDropdown}
            setFilterDropdown={setFilterDropdown}
            item={active}
            setItem={setActive}
            setBigGrid={setBigGrid}
            bigGrid={bigGrid}
            profile
          />
        </div>
      </div>

      {showSidebar || bigGrid ? (
        <div className="w-full flex pl-[310px] px-4 h-full">
          <div className="w-full grid grid-cols-1 md:grid-cols-3 mt-7 gap-5">
            {filteredCollections.map((collection) => (
              <Link key={collection.nft_collection_address} to={`/collections/${collection.nft_collection_address}`}>
                <Card
                  key={collection.id}
                  card_name={collection.name}
                  card_image={collection.image}
                  verified={collection.verified}
                  card_icon={'/images/home/collections/cards/creator_logo.svg'}
                  card_category_icon={'/images/home/collections/cards/ethereum.svg'}
                  floor_price={'Not listed yet'}
                  item_quantity={collection.items}
                  owner={collection.owner}
                />
              </Link>
            ))}
          </div>
        </div>
      ) : (
        <div className="w-full flex pl-[110px] px-4 h-full">
          {filteredCollections.map((collection) => (
            <ListCard
              key={collection.id}
              card_id={collection.nft_collection_address}
              card_name={collection.name}
              verified={collection.verified}
              card_category_icon={'/images/home/collections/cards/ethereum.svg'}
              floor_price={'Not listed yet'}
              item_quantity={collection.items}
              volume={'Not Listed Yet'}
              change={'Not Listed Yet'}
              owner={collection.owner}
            />
          ))}
        </div>
      )}
    </div>
  )
}
