import * as React from 'react'

const MarketplaceSVG = (props) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.4}
      d="M16.033 4.65H15.7l-2.817-2.817a.589.589 0 0 0-.825 0 .589.589 0 0 0 0 .825l1.992 1.991h-8.1L7.94 2.658a.589.589 0 0 0 0-.825.589.589 0 0 0-.824 0L4.308 4.649h-.333c-.75 0-2.308 0-2.308 2.134 0 .808.166 1.341.516 1.691.2.209.442.317.7.375.242.059.5.067.75.067h12.733c.259 0 .5-.017.734-.067.7-.166 1.233-.666 1.233-2.066 0-2.134-1.558-2.134-2.3-2.134Z"
    />
    <path d="M16.383 8.917H3.633c-.241 0-.508-.009-.75-.075l1.05 6.408c.242 1.433.867 3.084 3.642 3.084h4.675c2.808 0 3.308-1.409 3.608-2.984l1.259-6.508a3.043 3.043 0 0 1-.734.075ZM12.4 12.542l-2.708 2.5a.636.636 0 0 1-.425.167.618.618 0 0 1-.442-.184l-1.25-1.25a.629.629 0 0 1 0-.883.629.629 0 0 1 .883 0l.825.825 2.267-2.092a.63.63 0 0 1 .883.034.614.614 0 0 1-.033.883Z" />
  </svg>
)

export default MarketplaceSVG
