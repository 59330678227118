import React, { useContext, useEffect, useState } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Styles from './BaseLayout.module.css'
import Topbar from '../Topbar/Topbar'
import { BackendUrl } from '../../config/constants'
import Web3 from 'web3'
import { SidebarContext } from '../../context/SidebarContext/GlobalProvider'
import { useActiveItem } from '../../hooks/setActiveItem'
import { ThemeContext } from '../../context/ThemeContext/ThemeProvider'
import { useEthers } from '@usedapp/core'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function BaseLayout({ children, noTopbar, noSidebar,onSearch }) {
  const { showSidebar, setShowSidebar } = useContext(SidebarContext)
  const { theme, setTheme } = useContext(ThemeContext)
  const [sideBarMobile, setSideBarMobile] = useState(false)
  const [tempfixed, setTempFixed] = useState(true)
  const [account, setAccount] = useState(null)
  const [token, setToken] = useState(null)
  const [activeItem] = useActiveItem()
  const { library } = useEthers()

  useEffect(() => {
    async function getAccount() {
      const web3 = new Web3(window.ethereum)
      try {
        await window.ethereum.enable()
        const res = await web3.eth.getAccounts()
        setAccount(res[0])
        setToken(localStorage.getItem('token'))
      } catch (error) {
        console.log(error)
      }
    }
    getAccount()
  }, [library])

  useEffect(() => {
    async function getSignature() {
      console.log(token)
      if (account && !token && library) {
        console.log('abcd')
        const res = await fetch(`${BackendUrl}/generate_message`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify({ ethaccount: account }), // Wrap the account address in a JSON object
        })
        const data = await res.json()
        console.log(data, 'data')

        let signature = await library.getSigner().signMessage(data)

        console.log(signature, 'signature')

        const res2 = await fetch(`${BackendUrl}/authenticate`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify({ signature: signature, message: data }), // Wrap the account address in a JSON object
        })
        let data2 = await res2.json()
        console.log(data2, 'data2')
        const expiryTime = new Date(new Date().getTime() + 30 * 60 * 1000)
        data2.expiryTime = expiryTime
        localStorage.setItem('token', data2)
      }
    }
    getSignature()
  }, [account, library])

  const handleTempFixed = () => {
    setTheme(!tempfixed ? 'light' : 'dark')
    setTempFixed(!tempfixed)
  }

  useEffect(() => {
    if (theme === 'dark') {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [theme]);

  return (
    <div className="w-full pb-5 bg-white dark:bg-black-shade2-background">
      {noTopbar ? null : (
        <div className={`${Styles.topBar} w-full bg-white dark:bg-black-shade2-background`}>
          <Topbar setSideBarMobile={setSideBarMobile} sideBarMobile={sideBarMobile} activeLink={activeItem} onSearch={onSearch} />
        </div>
      )}

      <div className={`flex w-full  ${noTopbar ? '' : 'md:mt-24'}`}>
        {noSidebar ? null : (
          <div
            className={`${
              sideBarMobile ? 'block' : 'hidden'
            } md:hidden w-[240px] bg-white absolute dark:bg-black-shade2-background z-20`}
          >
            <Sidebar
              fullSidebar={true}
              tempfixed={tempfixed}
              handleTempFixed={handleTempFixed}
              activeLink={activeItem}
            />
          </div>
        )}
        {noSidebar ? null : (
          <div
            className={`hidden md:flex w-[270px] bg-white dark:bg-black-shade2-background ${
              Styles.sideBar
            } ease-in-out duration-300 ${showSidebar ? 'translate-x-0' : '-translate-x-[170px]'}`}
          >
            <Sidebar
              fullSidebar={showSidebar}
              tempfixed={tempfixed}
              handleTempFixed={handleTempFixed}
              activeLink={activeItem}
            />

            <div className="absolute z-30 ml-[90%] mt-6">
              <div
                className="flex items-center justify-center w-12 h-12 bg-white rounded-full cursor-pointer dark:bg-black-shade2-background"
                onClick={() => setShowSidebar(!showSidebar)}
              >
                <img src="/images/Sidebar/arrow-left.svg" alt="arrow-left" />
              </div>
            </div>
          </div>
        )}

        <div
          className={`w-full md:w-[calc(100%-270px)] md:ml-[270px] ease-in-out duration-300 ${
            showSidebar ? ' translate-x-0' : '-translate-x-[100px]'
          }`}
        >
          {children}
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
