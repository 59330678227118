import React, { useState, useEffect } from 'react'
import DribbleSVG from '../../../svgs/dribble'
import TwitterSVG from '../../../svgs/twitter'
import HeadingTags from '../../Common/HeadingTags'
import UploadImage from '../../Common/UploadImage'
import UploadImageFlex from '../../Common/UploadImageFlex'
import { BackendUrl } from '../../../config/constants'
import { useEthers } from '@usedapp/core'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function SettingsCard() {
  const [profileImage, setProfileImage] = useState(null)
  const [coverImage, setCoverImage] = useState(null)
  const [name, setName] = useState('')
  const [bio, setBio] = useState('')
  const [websiteUrl, setWebsiteUrl] = useState('')
  const [twitter, setTwitter] = useState('')
  const [profileFile, setProfileFile] = useState(null)
  const [coverFile, setCoverFile] = useState(null)
  const [token, setToken] = useState(null)
  const { account } = useEthers()

  useEffect(() => {
    const getTokenFromLocalStorage = async () => {
      const token = localStorage.getItem('token')
      setToken(token)
    }
    getTokenFromLocalStorage()
  }, [])

  useEffect(() => {
    // get user profile

    async function getUser() {
      const response = await fetch(`${BackendUrl}/user/${account}`, {
        method: 'GET',

      })
      const data = await response.json()
      console.log(data)
      if (data.detail === 'User not found') {
        return
      }
      if (data.profile_picture) {
        setProfileImage(BackendUrl + '/' + data.profile_picture)
      }
      if (data.cover_image) {
        setCoverImage(BackendUrl + '/' + data.cover_image)
      }
      setName(data.name)
      setBio(data.bio)
      setWebsiteUrl(data.website_url)
      setTwitter(data.twitter)
    }
    if (account && token) {
      getUser()
    }
  }, [account, token])

  const handleSubmit = async () => {
    if (!account) {
      toast.error('Please connect your wallet first.')
      return
    }

    const formData = new FormData()
    formData.append('wallet', account)
    formData.append('name', name)
    formData.append('bio', bio)
    formData.append('website_url', websiteUrl)
    formData.append('twitter', twitter)

    // Add images if available
    if (profileFile) {
      formData.append('profile_picture', profileFile)
    }
    if (coverFile) {
      formData.append('cover_image', coverFile)
    }
    // print stuff inside formData
    console.log(formData.keys())
    try {
      const response = await axios.post(`${BackendUrl}/user`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      console.log(response)
      if (response.data.message === 'User updated successfully' || response.data.message === 'User created successfully') {
        toast.success('Profile updated successfully!')
        window.location.reload()
      }
    } catch (error) {
      toast.error('Failed to update profile. Please try again later.')
      console.error(error)
    }
  }

  return (
    <div className="w-full p-5 md:p-9 bg-white dark:bg-black-shade2-background rounded-[10px] ">
      <HeadingTags name={'Profile Image'} />
      <UploadImageFlex image={profileImage} setImage={setProfileImage} setFile={setProfileFile} />

      <div className="mt-10">
        <HeadingTags name={'Cover Image'} />
        <UploadImage image={coverImage} setImage={setCoverImage} setFile={setCoverFile} />
      </div>

      <div className="mt-10">
        <HeadingTags name={'Name'} required />
        <div className="mt-5">
          <input
            className="bg-transparent w-full px-5 py-4 font-gilroy placeholder:font-medium dark:placeholder:text-light-text placeholder:text-dark-text font-semibold text-dark-text dark:text-dark-white-color focus:outline-none border-[1.5px] rounded-lg border-light-text border-opacity-50"
            type={'text'}
            placeholder="Enter your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>

      <div className="mt-10">
        <HeadingTags name={'Bio'} required />
        <div className="mt-5">
          <textarea
            className="bg-transparent w-full px-5 py-4 font-gilroy placeholder:font-medium dark:placeholder:text-light-text placeholder:text-dark-text font-semibold text-dark-text dark:text-dark-white-color focus:outline-none border-[1.5px] rounded-lg border-light-text border-opacity-50"
            placeholder="Write something about yourself"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
          />{' '}
        </div>
      </div>

      <div className="mt-10">
        <HeadingTags name={'Link your socials'} required />
        <div className="mt-5 flex items-center">
          <DribbleSVG className="fill-dark-text w-5 h-5 absolute z-10 ml-5" />
          <input
            className="bg-transparent w-full pl-14 pr-5 py-4 font-gilroy placeholder:font-medium dark:placeholder:text-light-text placeholder:text-dark-text font-semibold text-dark-text dark:text-dark-white-color focus:outline-none border-[1.5px] rounded-lg border-light-text border-opacity-50"
            type={'text'}
            placeholder="Enter Website Url."
            value={websiteUrl}
            onChange={(e) => setWebsiteUrl(e.target.value)}
          />
        </div>

        <div className="mt-5 flex items-center">
          <TwitterSVG className="fill-dark-text w-5 h-5 absolute z-10 ml-5" />
          <input
            className="bg-transparent w-full pl-14 pr-8 py-4 font-gilroy placeholder:font-medium dark:placeholder:text-light-text placeholder:text-dark-text font-semibold text-dark-text dark:text-dark-white-color focus:outline-none border-[1.5px] rounded-lg border-light-text border-opacity-50"
            type={'text'}
            placeholder="Enter twitter Username"
            value={twitter}
            onChange={(e) => setTwitter(e.target.value)}
          />
        </div>
        <div className="-mt-7 mb-9 flex items-center justify-end mr-4">
          <span className="absolute font-gilroy text-dark-text dark:text-dark-white-color font-semibold">Link</span>
        </div>
      </div>

      <div className="mt-12">
        <button
          className="bg-primary-green text-white font-gilroy font-bold text-xl py-4 mt-4 rounded-lg w-full"
          onClick={handleSubmit}
        >
          Save Changes
        </button>
      </div>
    </div>
  )
}
