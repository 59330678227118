import React from 'react'

const BidSVG = (props) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M17.117 15.866a.618.618 0 0 1-.442.183.618.618 0 0 1-.442-.183l-4.125-4.125.442-.442.442-.442 4.125 4.125a.629.629 0 0 1 0 .884ZM5.391 7.733l4.834 4.833a.83.83 0 0 1 0 1.175l-.75.759a1.7 1.7 0 0 1-2.409 0L3.45 10.883a1.705 1.705 0 0 1 0-2.4l.758-.758a.848.848 0 0 1 1.183.008Z" />
    <path d="m15.492 8.491-3.176 3.167a.845.845 0 0 1-1.2 0L6.3 6.841a.845.845 0 0 1 0-1.2l3.175-3.175a1.705 1.705 0 0 1 2.4 0l3.617 3.617a1.715 1.715 0 0 1 0 2.408ZM6.667 18.125h-5a.63.63 0 0 1-.625-.625.63.63 0 0 1 .625-.625h5a.63.63 0 0 1 .625.625.63.63 0 0 1-.625.625Z" />
  </svg>
)

export default BidSVG
