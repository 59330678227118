import * as React from 'react'

const InstagramSVG = (props) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13.255 15.598a4.043 4.043 0 0 0 2.343-2.343c.201-.502.335-1.104.368-1.941 0-.201 0-.368.034-.536V4.72c-.034-.837-.167-1.44-.368-1.942a3.585 3.585 0 0 0-.937-1.406c-.402-.401-.87-.736-1.406-.937-.502-.2-1.105-.335-1.942-.368C10.477 0 10.176 0 8 0S5.556 0 4.686.033c-.837.034-1.44.168-1.941.369-.502.234-1.004.535-1.406.937a4.036 4.036 0 0 0-.904 1.44C.235 3.28.1 3.882.067 4.718 0 5.557 0 5.825 0 8s0 2.444.033 3.314c.034.837.168 1.44.369 1.941.2.536.535 1.004.937 1.406.402.402.87.737 1.406.937.502.201 1.104.335 1.941.368C5.556 16 5.824 16 8 16s2.444 0 3.314-.034c.87-.033 1.44-.167 1.941-.368Zm-5.222-1.004c-2.142 0-2.41 0-3.246-.033-.77-.034-1.205-.168-1.473-.268-.335-.134-.67-.335-.904-.603a2.51 2.51 0 0 1-.602-.937c-.134-.301-.268-.703-.302-1.506-.033-.837-.033-1.105-.033-3.247s0-2.41.033-3.247c.034-.736.168-1.171.268-1.44.134-.334.335-.669.603-.936.267-.268.569-.469.903-.603.302-.1.737-.234 1.507-.268.836-.033 1.104-.033 3.246-.033 2.143 0 2.41 0 3.247.033.77.034 1.172.168 1.473.268.335.134.67.335.904.603.268.267.469.569.602.937.1.268.235.703.268 1.473.034.836.034 1.104.034 3.246 0 2.143 0 2.377-.034 3.247-.033.77-.167 1.205-.268 1.473a2.667 2.667 0 0 1-1.506 1.506c-.268.1-.703.235-1.473.268-.87.067-1.138.067-3.247.067Z" />
    <path d="M8 3.883A4.114 4.114 0 0 0 3.883 8 4.114 4.114 0 0 0 8 12.117 4.114 4.114 0 0 0 12.117 8c0-2.243-1.84-4.117-4.117-4.117Zm0 6.795A2.686 2.686 0 0 1 5.322 8 2.686 2.686 0 0 1 8 5.322 2.686 2.686 0 0 1 10.678 8 2.644 2.644 0 0 1 8 10.678ZM13.255 3.75a.972.972 0 1 0-.97.971c.535-.033.97-.468.97-.97Z" />
  </svg>
)

export default InstagramSVG
